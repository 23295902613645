import { enumKeys } from '../../utils/enum-utils';

export enum EnumTypologieMessageAlerte {
  INFORMATION = 'INFORMATION',
  RESOLUTION = 'RESOLUTION',
  MAINTENANCE = 'MAINTENANCE',
}

export namespace EnumTypologieMessageAlerte {
  export function toString(statut: EnumTypologieMessageAlerte): string {
    switch (statut) {
      case EnumTypologieMessageAlerte.INFORMATION:
        return 'Information';
      case EnumTypologieMessageAlerte.RESOLUTION:
        return 'Résolution';
      case EnumTypologieMessageAlerte.MAINTENANCE:
        return 'Maintenance';
    }
  }

  export function all(): EnumTypologieMessageAlerte[] {
    return enumKeys(EnumTypologieMessageAlerte) as unknown as EnumTypologieMessageAlerte[];
  }
}
