import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MessageAlerte } from '@shared-ui';

export const SILENCED_ALERTE_IDS_KEY = 'silenced-alerte-ids';
export const ALERTE_SILENCED_KEY = 'alerte-silenced';

@Injectable({
  providedIn: 'root',
})
export class AlerteControlService {
  private silencedAlerteIds: string[] = localStorage.getItem(SILENCED_ALERTE_IDS_KEY)?.split(';') || [];
  private readonly silenced = new BehaviorSubject<boolean>(localStorage.getItem(ALERTE_SILENCED_KEY) === 'true');
  silenced$ = this.silenced.asObservable();

  setSilenced(value: boolean): void {
    if (!value) {
      this.silencedAlerteIds = [];
      localStorage.removeItem(SILENCED_ALERTE_IDS_KEY);
    }
    localStorage.setItem(ALERTE_SILENCED_KEY, value.toString());
    this.silenced.next(value);
  }

  addSilencedAlerte(alerte: MessageAlerte): void {
    this.silencedAlerteIds.push(alerte.id);
    localStorage.setItem(SILENCED_ALERTE_IDS_KEY, this.silencedAlerteIds.join(';'));
  }

  getSilencedAlerteIds(): string[] {
    return this.silencedAlerteIds;
  }
}
