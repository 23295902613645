<div class="single-filter-container">
  <label class="filter-label"> {{ dispositifFilter.label }} </label>
  <lib-custom-multiselect
    *ngIf="!loadingMap.get(DISPOSITIF)"
    [(dataList)]="dispositifFilter.dataList!"
    [selectedList]="dispositifFilter.selectedList!"
    (selectedListChange)="rechercheTransverseFiltersService.onSelectionChange($event, dispositifFilter)"
    placeholder="Dispositif"
    name="Dispositif"
    [showSelectIcon]="true"
    [isFrontAutocomplete]="true"
  ></lib-custom-multiselect>
</div>
<div class="single-filter-container">
  <label class="filter-label"> {{ aapFilter.label }} </label>
  <lib-custom-multiselect
    (autocompleteEvent)="rechercheTransverseFiltersService.autocomplete($event, aapFilter, loadingMap)"
    (selectedListChange)="rechercheTransverseFiltersService.onSelectionChange($event, aapFilter)"
    [(dataList)]="aapFilter.dataList!"
    [selectedList]="aapFilter.selectedList!"
    placeholder="Appel à projet"
    name="Aap"
    class="filter"
    [isLoading]="loadingMap.get(AAP)"
    [autocompleteStart]="1"
  >
  </lib-custom-multiselect>
</div>
<div class="single-filter-container">
  <label class="filter-label"> {{ acronymeFilter.label }} </label>
  <lib-custom-multiselect
    (autocompleteEvent)="rechercheTransverseFiltersService.autocomplete($event, acronymeFilter, loadingMap)"
    (selectedListChange)="rechercheTransverseFiltersService.onSelectionChange($event, acronymeFilter)"
    [(dataList)]="acronymeFilter.dataList!"
    [selectedList]="acronymeFilter.selectedList!"
    placeholder="Acronyme"
    name="Acronyme"
    class="filter"
    [isLoading]="loadingMap.get(ACRONYME)"
    [autocompleteStart]="1"
  >
  </lib-custom-multiselect>
</div>
<div class="single-filter-container">
  <label class="filter-label"> {{ denominationSocialeFilter.label }} </label>
  <lib-custom-multiselect
    (autocompleteEvent)="rechercheTransverseFiltersService.autocomplete($event, denominationSocialeFilter, loadingMap)"
    (selectedListChange)="rechercheTransverseFiltersService.onSelectionChange($event, denominationSocialeFilter)"
    [(dataList)]="denominationSocialeFilter.dataList!"
    [selectedList]="denominationSocialeFilter.selectedList!"
    placeholder="Nom de l'entreprise"
    name="denominationSociale"
    class="filter"
    [isLoading]="loadingMap.get(DENOMINATION_SOCIALE)"
    [autocompleteStart]="1"
  >
  </lib-custom-multiselect>
</div>
<div class="single-filter-container">
  <label class="filter-label"> {{ processFR30Filter.label }} </label>
  <lib-custom-multiselect
    [(dataList)]="processFR30Filter.dataList!"
    [selectedList]="processFR30Filter.selectedList!"
    (selectedListChange)="rechercheTransverseFiltersService.onSelectionChange($event, processFR30Filter)"
    placeholder="statut process FR30"
    name="StatutProcessFR30"
    [showSelectIcon]="true"
    [isFrontAutocomplete]="true"
  ></lib-custom-multiselect>
</div>
