import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EnumTypologieMessageAlerte } from '../../shared/enums/enum.typologie-message-alerte';

@Component({
  selector: 'lib-card-message-alerte',
  templateUrl: './card-message-alerte.component.html',
  styleUrls: ['./card-message-alerte.component.scss'],
})
export class CardMessageAlerteComponent {
  @Input() type: EnumTypologieMessageAlerte;
  @Input() titre: string;
  @Input() message: string;
  @Input() dateDebut?: Date;
  @Input() dateFin?: Date;
  @Input() cancel = true;
  @Input() content: string;
  @Input() heureDebut?: string;
  @Input() heureFin?: string;
  @Output() closed = new EventEmitter<void>();

  getIcon(type: EnumTypologieMessageAlerte) {
    switch (type) {
      case EnumTypologieMessageAlerte.INFORMATION:
        return 'icon-action-info';
      case EnumTypologieMessageAlerte.MAINTENANCE:
        return 'icon-info-warning';
      case EnumTypologieMessageAlerte.RESOLUTION:
        return 'icon-action-check-circle';
      default:
        return '';
    }
  }

  close() {
    this.closed.emit();
  }

  protected readonly EnumTypologieMessageAlerte = EnumTypologieMessageAlerte;
}
