import { enumKeys } from '../../utils/enum-utils';

export enum EnumPositionnement {
  ACCUEIL = 'ACCUEIL',
  TACHES_A_REALISER = 'TACHES_A_REALISER',
  ANALYSE = 'ANALYSE',
  ELIGIBILITE = 'ELIGIBILITE',
  EVALUATION = 'EVALUATION',
  AUDITION = 'AUDITION',
  INSTRUCTION_APPROFONDIE = 'INSTRUCTION_APPROFONDIE',
  ACCUEIL_NC = 'ACCUEIL_NC',
  PROJET = 'PROJET',
  CONSORTIUM = 'CONSORTIUM',
  CONTRACTUALISATION = 'CONTRACTUALISATION',
  SUIVI = 'SUIVI',
  RECHERCHE_TRANSVERSE = 'RECHERCHE_TRANSVERSE',
  CANDIDAT_EVALUATEUR = 'CANDIDAT_EVALUATEUR',
  ORGANISME_EVALUATEUR = 'ORGANISME_EVALUATEUR',
}

export namespace EnumPositionnement {
  export function toString(statut: EnumPositionnement): string {
    switch (statut) {
      case EnumPositionnement.ACCUEIL:
        return "Page d'accueil";
      case EnumPositionnement.TACHES_A_REALISER:
        return 'Tâches à réaliser';
      case EnumPositionnement.ANALYSE:
        return 'Analyse';
      case EnumPositionnement.ELIGIBILITE:
        return 'Eligibilite';
      case EnumPositionnement.EVALUATION:
        return 'Evaluation';
      case EnumPositionnement.AUDITION:
        return 'Audition';
      case EnumPositionnement.INSTRUCTION_APPROFONDIE:
        return 'Instruction approfondie';
      case EnumPositionnement.ACCUEIL_NC:
        return "Page d'accueil (utilisateur non connecté)";
      case EnumPositionnement.PROJET:
        return 'Projet';
      case EnumPositionnement.CONSORTIUM:
        return 'Consortium';
      case EnumPositionnement.CONTRACTUALISATION:
        return 'Contractualisation';
      case EnumPositionnement.SUIVI:
        return 'Suivi';
      case EnumPositionnement.RECHERCHE_TRANSVERSE:
        return 'Recherche transverse';
      case EnumPositionnement.CANDIDAT_EVALUATEUR:
        return 'Candidat / Evaluateur';
      case EnumPositionnement.ORGANISME_EVALUATEUR:
        return 'Organisme / Evaluateur';
    }
  }

  export function all(): EnumPositionnement[] {
    return enumKeys(EnumPositionnement) as unknown as EnumPositionnement[];
  }
}
