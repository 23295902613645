<div [ngClass]="{ 'collapse-transition': true, show: displayed }">
  @if (alertes.length) {
  <div class="alertes-publies">
    @for (alerte of alertes; track alerte) {
    <lib-card-message-alerte
      [type]="alerte.typologie"
      [message]="alerte.description"
      [titre]="alerte.titre"
      [dateDebut]="alerte.dateDebut"
      [dateFin]="alerte.dateFin"
      [heureDebut]="alerte.heureDebut"
      [heureFin]="alerte.heureFin"
      (closed)="closeAlerte(alerte)"
    ></lib-card-message-alerte>
    }
  </div>
  }
</div>
