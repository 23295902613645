import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ConfirmModalComponent,
  CurrentYearLabelValue,
  DateTimeUtils,
  DonneesFinancieres,
  DonneesFinancieresHttpService,
  DonneesFinancieresService,
  EnumProjetEtape,
  EnumProjetStatut,
  EnumTypeBailleurDeFonds,
  EnumTypePartenaire,
  ModalResult,
  PeriodeFinanciere,
  ShowToastrService,
  SignalService,
  SituationFinanciere,
  Status,
  Structure,
} from '@shared-ui';
import { finalize, Observable } from 'rxjs';

declare const dsLib: any;

@Component({
  selector: 'lib-projet-consortium-donnees-financieres',
  templateUrl: './projet-consortium-donnees-financieres.component.html',
  styleUrls: ['./projet-consortium-donnees-financieres.component.scss'],
})
export class ProjetConsortiumDonneesFinancieresComponent implements OnInit {
  protected tabs = new dsLib.Tabs('dstab');

  protected isEvaluateur = false;
  protected isProjetDepot = false;
  protected comptesResultats: PeriodeFinanciere[] = [];
  protected plansFinancement: PeriodeFinanciere[] = [];
  protected plansTresorerie: PeriodeFinanciere[] = [];
  protected situationFinanciere: SituationFinanciere | undefined;
  protected status: Status = Status.NON_VALIDE;
  protected isQuestionEnabled = false;
  protected isOlderThanHeightYears = false;
  protected partenaire;
  protected bailleurDeFonds = false;
  protected lastBalance: number;

  protected readonly Status = Status;
  protected readonly minNbOfColumns = 5;
  protected readonly readonlyMessage = 'Vous êtes en lecture seule sur cette page. Vous ne pouvez pas éditer son contenu.';
  protected readonly EUROS_AMOUNTS_MESSAGE = "Les montants sont en milliers d'euros TTC.";
  protected readonly OLDER_THAN_HEIGHT_MESSAGE = "Votre entreprise a plus de 8 ans. Le reste de ce tableau n'est pas nécessaire.";
  protected readonly MESSAGE_PORTEUR =
    "A renseigner par le porteur.<br> Prolonger le nombre de colonnes jusqu'à l'année de fin des travaux de R&D du projet.";
  protected readonly MESSAGE_COLLABORATIF =
    'A renseigner par le chef de file et par chaque partenaire "entreprise".<br> Prolonger le nombre de colonnes jusqu\'à l\'année de fin des travaux de R&D du projet.';

  protected structure: Structure | undefined;
  protected structureId: string;
  protected donneesFinancieres: DonneesFinancieres;

  constructor(
    protected service: DonneesFinancieresService,
    protected httpService: DonneesFinancieresHttpService,
    protected signalService: SignalService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected showToastrService: ShowToastrService,
    protected matDialog: MatDialog
  ) {
    ({ structureId: this.structureId } = this.route.snapshot.params);
    this.partenaire = this.router.getCurrentNavigation()?.extras?.state?.['partenaire'];
    this.bailleurDeFonds = this.router.getCurrentNavigation()?.extras?.state?.['bailleurDeFonds'] ?? false;
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return true;
  }

  ngOnInit(): void {
    this.isEvaluateur = history.state?.isEvaluateur || false;
    this.isProjetDepot = history.state?.etapeProjet === EnumProjetEtape.DEPOT && history.state?.statut !== EnumProjetStatut.EN_COURS;
    this.service.resetSharedValueByPeriodBySaisie();
    this.route.data.subscribe(data => {
      this.structure = data['structure'];
      this.donneesFinancieres = this.structure?.donneesFinancieres || {};
      this.handleCreationDate();
      this.initLastBalanceAndCurrentFinancialYear();
    });
  }

  protected initLastBalanceAndCurrentFinancialYear(): void {
    this.setInitialLastBalance();

    let currentFinancialYear: number | undefined = this.signalService.getCurrentFinancialYear();
    if (!currentFinancialYear) {
      currentFinancialYear = this.service.findCurrentYearLabelAndValue(this.donneesFinancieres?.comptesResultats || [])?.value;
      if (!this.isEvaluateur && !currentFinancialYear) {
        this.triggerModal();
      } else {
        this.signalService.setCurrentFinancialYear(currentFinancialYear || new Date().getFullYear());
        this.initDonneesFinancieres();
      }
    } else {
      this.initDonneesFinancieres();
    }
  }

  protected setInitialLastBalance(): void {
    if (this.donneesFinancieres?.soldeTresorerieDernierBilan) {
      this.signalService.setLastBalance(this.donneesFinancieres.soldeTresorerieDernierBilan);
    }
    this.lastBalance = this.signalService.getLastBalance();
  }

  protected triggerModal(): void {
    this.service
      .triggerLastBalanceAndCurrentFinancialYearModal('Pré requis éléments financiers')
      .subscribe((result: ModalResult | undefined) => {
        if (result) {
          this.signalService.setCurrentFinancialYear(+result.currentYear);
          this.signalService.setLastBalance(result.soldeTresorerieDernierBilan || 0);
          this.lastBalance = this.signalService.getLastBalance();
        }
        this.initDonneesFinancieres();
      });
  }

  protected initDonneesFinancieres(): void {
    this.donneesFinancieres = this.service.buildDonneesFinancieres(this.donneesFinancieres);
    ({ statut: this.status = Status.NON_VALIDE } = this.donneesFinancieres);
    this.comptesResultats = this.donneesFinancieres.comptesResultats || [];
    this.plansFinancement = this.donneesFinancieres.planFinancements || [];
    this.plansTresorerie = this.donneesFinancieres.planTresoreries || [];
    this.situationFinanciere = this.donneesFinancieres.situationFinanciere;
  }

  protected handleCreationDate(): void {
    const organisationCreationDate: string | undefined = this.structure?.informationsReferentiel?.organisationCreationDate || '';
    const creationDate: Date | undefined = DateTimeUtils.stringToDate(organisationCreationDate);
    if (creationDate) {
      // S'il y a une date de création, on désactive la question pour vérifier si la structure a plus de 8 ans
      this.isQuestionEnabled = false;
      const now = new Date();
      const delta: number = DateTimeUtils.calculateNbOfYearsBetweenToDate(creationDate, now);
      this.isOlderThanHeightYears = delta > 8;
    } else {
      // S'il n'y a pas de date de création et qu'on n'est pas évaluateur, on active la question
      this.isQuestionEnabled = !this.isEvaluateur;
    }
  }

  protected onUpdateCurrentFinancialYear(): void {
    this.service
      .triggerLastBalanceAndCurrentFinancialYearModal("Modifier l'exercice en cours / Solde trésorerie du dernier bilan", true)
      .subscribe((result: ModalResult | undefined) => {
        if (result) {
          this.signalService.setCurrentFinancialYear(+result?.currentYear);
          this.signalService.setLastBalance(result.soldeTresorerieDernierBilan || 0);
          this.lastBalance = this.signalService.getLastBalance();
          this.comptesResultats = this.updateDateAccordingToNewCurrentYear(
            this.signalService.getComptesResultats(),
            result.currentYear,
            true
          );
          this.plansFinancement = [
            ...this.updateDateAccordingToNewCurrentYear(this.signalService.getPlanFinancement(), result.currentYear, false),
          ];
          this.donneesFinancieres.comptesResultats = this.comptesResultats;
          this.donneesFinancieres.planFinancements = this.plansFinancement;
        }
      });
  }

  protected updateDateAccordingToNewCurrentYear(
    pf: PeriodeFinanciere[],
    selectedCurrentYear: string,
    isComptesResultats: boolean
  ): PeriodeFinanciere[] {
    const currentYearLabelValue: CurrentYearLabelValue | undefined = this.service.findCurrentYearLabelAndValue(pf);

    // Si l'exercice en cours sélectionné est déjà l'exercice en cours
    if (selectedCurrentYear === currentYearLabelValue?.label) {
      return pf;
    }

    // Si l'exercice en cours sélectionné fait partie des périodes financières existantes
    const currentYearIndex: number = pf.findIndex(
      (periodeFinanciere: PeriodeFinanciere) => selectedCurrentYear === periodeFinanciere.periode
    );
    if (currentYearIndex !== -1) {
      let sliced: PeriodeFinanciere[] = pf.slice(currentYearIndex);
      if (sliced.length < this.minNbOfColumns) {
        const added: PeriodeFinanciere[] = this.addMissingColumns(
          isComptesResultats,
          +selectedCurrentYear + sliced.length,
          +selectedCurrentYear + this.minNbOfColumns - 1
        );
        sliced = [...sliced, ...added];
      }
      sliced[0].periode = this.service.CURRENT_YEAR_LABEL;
      return sliced;
    }

    // Si l'exercice en cours sélectionné est avant l'exercice en cours existant ou après la dernière année
    if (currentYearLabelValue) {
      const { value: existingCurrentYearValue, label: existingCurrentYearLabel } = currentYearLabelValue;
      const newCurrentYear: number = +selectedCurrentYear;
      // Si l'exercice en cours sélectionné est avant l'exercice en cours existant
      if (newCurrentYear < existingCurrentYearValue) {
        const addedBefore: PeriodeFinanciere[] = this.addMissingColumns(isComptesResultats, newCurrentYear, existingCurrentYearValue - 1);
        addedBefore[0].periode = this.service.CURRENT_YEAR_LABEL;
        pf[0].periode = existingCurrentYearLabel;
        return [...addedBefore, ...pf];
      }
      // Si l'exercice en cours sélectionné est après la dernière année
      const lastPf: PeriodeFinanciere | undefined = pf.at(-1);
      if (lastPf) {
        const lastYear: number = +lastPf.periode;
        if (lastYear < newCurrentYear) {
          const newLastYear: number = newCurrentYear + this.minNbOfColumns - 1;
          const newPf: PeriodeFinanciere[] = this.addMissingColumns(isComptesResultats, newCurrentYear, newLastYear);
          newPf[0].periode = this.service.CURRENT_YEAR_LABEL;
          return newPf;
        }
      }
    }
    return pf;
  }

  protected addMissingColumns(isComptesResultats: boolean, startYear: number, endYear: number): PeriodeFinanciere[] {
    const pf: PeriodeFinanciere[] = [];
    for (let i: number = startYear; i <= endYear; i++) {
      pf.push(
        isComptesResultats
          ? this.service.buildSingleComptesResultatsPeriodeFinanciere('' + i)
          : this.service.buildSinglePlanFinancementPeriodeFinanciere('' + i)
      );
    }
    return pf;
  }

  protected onSave() {
    this.saveData(Status.NON_VALIDE);
  }

  protected onValidate() {
    const dialogRef = this.matDialog.open(ConfirmModalComponent, {
      data: {
        title: 'Validation',
        description: `<p>Vous êtes sur le point de valider les données existantes. Cette action nécessite une validation de votre part. Souhaitez-vous continuer ?</p>`,
        textGoButton: 'Confirmer',
        textReturnButton: 'Annuler',
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.saveData(Status.VALIDE);
      }
    });
  }

  protected onUpdate() {
    const dialogRef = this.matDialog.open(ConfirmModalComponent, {
      data: {
        title: 'Modification',
        description: `<p>Vous êtes sur le point de modifier les données existantes. Cette action nécessite une validation de votre part et toute modification sera définitive. Souhaitez-vous continuer ?</p>`,
        textGoButton: 'Confirmer',
        textReturnButton: 'Annuler',
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.saveData(Status.NON_VALIDE);
      }
    });
  }

  protected saveData(status: Status) {
    // Récupération de toutes les données des onglets
    const comptesResultats: PeriodeFinanciere[] = this.signalService.getComptesResultats();
    const plansFinancement: PeriodeFinanciere[] = this.signalService.getPlanFinancement();
    const plansTresorerie: PeriodeFinanciere[] = this.signalService.getPlansTresorerie();
    const situationFinanciere: SituationFinanciere = this.signalService.getSituationFinanciere();
    const previousStatus: Status = this.donneesFinancieres.statut || Status.NON_VALIDE;
    this.donneesFinancieres = {
      ...this.donneesFinancieres,
      statut: status,
      comptesResultats,
      planFinancements: plansFinancement,
      planTresoreries: plansTresorerie,
      situationFinanciere,
    };
    if (this.signalService.getLastBalance() !== 0) {
      this.donneesFinancieres.soldeTresorerieDernierBilan = this.signalService.getLastBalance();
    }
    this.putStructureDonneesFinancieres(this.structureId, this.donneesFinancieres)
      .pipe(
        finalize(() => {
          if (this.structure) {
            this.signalService.setStructure(this.structure);
          }
        })
      )
      .subscribe({
        next: (response: Structure) => {
          if (status === Status.VALIDE) {
            this.showToastrService.success('Les éléments financiers de la structure ont bien été modifiés');
          }
          this.status = status;
          this.structure = response;
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
          this.status = previousStatus;
          this.donneesFinancieres.statut = previousStatus;
          if (this.structure) {
            this.structure = {
              ...this.structure,
              donneesFinancieres: this.donneesFinancieres,
            };
          }
        },
      });
  }

  protected putStructureDonneesFinancieres(structureId: string, donneesFinancieres: DonneesFinancieres): Observable<Structure> {
    return this.httpService.putStructureDonneesFinancieres(structureId, donneesFinancieres);
  }

  protected onGoToConsortiumInfo(): void {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  protected readonly EnumTypePartenaire = EnumTypePartenaire;
  protected readonly EnumTypeBailleurDeFonds = EnumTypeBailleurDeFonds;
}
