import { HttpErrorResponse } from '@angular/common/http';
import { Component, DestroyRef, Inject, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  EnumPositionnement,
  FilterDataInterface,
  FilterType,
  IntervenantRoles,
  PermissionUtils,
  PreferencesUtilisateurService,
  PreferenceUtilisateur,
  RechercheTransverseService,
  ShowToastrService,
  TableColumnConfiguration,
  TableUtils,
  Utilisateur,
  UtilisateurAbstractService,
} from '@shared-ui';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FilterConfig,
  RECHERCHE_TRANSVERSE_FILTERS_CONFIG,
  RECHERCHE_TRANSVERSE_TABLE_CONFIG,
  RechercheTransverseFiltersConfig,
} from './recherche-transverse.config';
import { combineLatest, map, Observable, of, tap, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

declare const dsLib: any;

@Component({
  selector: 'lib-recherche-transverse',
  templateUrl: './recherche-transverse.component.html',
  styleUrls: ['./recherche-transverse.component.scss'],
})
export class RechercheTransverseComponent implements OnInit {
  private readonly DASHBOARD_PREFERENCE_KEY = 'search_columns';
  private readonly TABLE_CONF = RECHERCHE_TRANSVERSE_TABLE_CONFIG;
  private readonly FILTERS_CONFIG = RECHERCHE_TRANSVERSE_FILTERS_CONFIG;

  destroyRef = inject(DestroyRef);

  tabs = new dsLib.Tabs('dstab');

  displayedColumns: string[] = [];
  tableConf: TableColumnConfiguration[];
  filtersConfig: RechercheTransverseFiltersConfig;
  private readonly isRestrictedTableHeader: boolean;
  utilisateur: Utilisateur;
  isDoubleProfile = false;
  isSiteEval = false;
  preferencesUtilisateur: PreferenceUtilisateur;

  constructor(
    @Inject('environment') private environment: any,
    public showToastrService: ShowToastrService,
    public preferenceUtilisateurService: PreferencesUtilisateurService,
    private route: ActivatedRoute,
    private utilisateurService: UtilisateurAbstractService,
    private rechercheTransverseService: RechercheTransverseService,
    private router: Router
  ) {
    this.isRestrictedTableHeader = this.route.snapshot.data?.['restrictedTableHeader'];
    this.isDoubleProfile = localStorage.getItem('DOUBLE_PROFILE') === 'true';
    this.isSiteEval = this.isRestrictedTableHeader;
  }

  ngOnInit(): void {
    combineLatest([this.getUtilisateur(), this.getPreference(), this.loadMultiSelectValues()])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.initConfig();
          this.handlePreferences();
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  private initConfig() {
    if (!this.isRestrictedTableHeader) {
      this.tableConf = this.TABLE_CONF;
      this.filtersConfig = this.FILTERS_CONFIG;
      return;
    }
    this.initRestrictedConfig();
  }

  private getUtilisateur() {
    return this.utilisateurService.getUtilisateurObservable().pipe(
      takeUntilDestroyed(this.destroyRef),
      tap(utilisateur => (this.utilisateur = utilisateur))
    );
  }

  private initRestrictedConfig() {
    const userRole = PermissionUtils.resolveIntervenantRoles(this.utilisateur);
    this.tableConf = userRole ? this.TABLE_CONF.filter(this.filterByUserRole(userRole)) : [];
    this.filtersConfig = {} as RechercheTransverseFiltersConfig;
    Object.keys(this.FILTERS_CONFIG).forEach(key => {
      this.filtersConfig[key] = userRole ? this.FILTERS_CONFIG[key].filter(this.filterByUserRole(userRole)) : [];
    });
  }

  private filterByUserRole(userRole: IntervenantRoles) {
    return (tcc: any) => !tcc.restrictedRoles || tcc.restrictedRoles.includes(userRole);
  }

  fillDisplayedColumns() {
    this.displayedColumns = ['select'];
    this.tableConf.forEach(column => {
      if (column.visible) {
        this.displayedColumns.push(column.id);
      }
    });
  }

  getPreference() {
    return this.preferenceUtilisateurService.getPreferenceForCurrentUser().pipe(
      takeUntilDestroyed(this.destroyRef),
      tap((response: PreferenceUtilisateur) => {
        this.preferencesUtilisateur = response;
      }),
      catchError((err: HttpErrorResponse) => {
        if (err?.status !== 404) {
          return throwError(() => err);
        }
        return of(true);
      })
    );
  }

  handlePreferences() {
    if (this.preferencesUtilisateur) {
      const displayedColumns = this.preferencesUtilisateur?.preferences?.[this.DASHBOARD_PREFERENCE_KEY];
      this.tableConf = TableUtils.handlePreferenceResponse(displayedColumns, this.tableConf);
    }
    this.fillDisplayedColumns();
  }

  updatePreferences(columns: string[]) {
    this.preferenceUtilisateurService
      .updatePreference(
        this.DASHBOARD_PREFERENCE_KEY,
        columns.filter(col => col !== 'select')
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: PreferenceUtilisateur) => {
          this.preferencesUtilisateur = response;
          this.handlePreferences();
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  onClickGoToChoice(): void {
    localStorage.removeItem('CHOSEN_PROFILE');
    this.router.navigate(['evaluateur-choice']);
  }

  redirectToCandidats(): void {
    window.location.href = this.environment.espaceCandidat + '/projets';
  }

  private loadFieldValues(fields: string[]): Observable<{ [key: string]: FilterDataInterface[] }> {
    const observables = fields.map(field =>
      this.rechercheTransverseService.getAllFieldValues(field).pipe(
        takeUntilDestroyed(this.destroyRef),
        map(response => ({ [field]: (response.body as string[]).map(value => ({ id: value, value: value })) }))
      )
    );

    return combineLatest(observables).pipe(map(results => results.reduce((acc, result) => ({ ...acc, ...result }), {})));
  }

  private loadMultiSelectValues() {
    const multiselectFilters = Object.keys(this.FILTERS_CONFIG)
      .map(key => this.FILTERS_CONFIG[key]!.filter((filter: FilterConfig) => filter.type === FilterType.MULTI_SELECT))
      .flat();
    const fields = multiselectFilters.map(filter => filter.source);
    return this.loadFieldValues(fields).pipe(
      takeUntilDestroyed(this.destroyRef),
      tap({
        next: data => {
          multiselectFilters.forEach(filter => {
            filter.dataList = data[filter.source];
          });
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      })
    );
  }

  protected readonly EnumPositionnement = EnumPositionnement;
}
