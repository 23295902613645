import { enumKeys } from '../../utils/enum-utils';

export enum EnumTypeBailleurDeFonds {
  ETAT = 'ETAT',
  CMACGM = 'CMACGM',
}

export namespace EnumTypeBailleurDeFonds {
  export function toString(bailleurDeFonds: EnumTypeBailleurDeFonds): string {
    switch (bailleurDeFonds) {
      case EnumTypeBailleurDeFonds.ETAT:
        return 'Etat';
      case EnumTypeBailleurDeFonds.CMACGM:
        return 'CMA-CGM';
    }
  }

  export function all(): EnumTypeBailleurDeFonds[] {
    return enumKeys(EnumTypeBailleurDeFonds) as unknown as EnumTypeBailleurDeFonds[];
  }
}
