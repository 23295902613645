import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe, PercentPipe } from '@angular/common';
import { DisplayColumn } from '@shared-ui';

@Pipe({
  name: 'formatNumber',
})
export class FormatNumberPipe implements PipeTransform {
  transform(value: any, col?: DisplayColumn) {
    switch (col?.cellType) {
      case 'number':
        return this.valueToNumber(value, col);
      case 'percent':
        return this.toPercent(value, col);
      default:
        return value !== undefined && value !== 'undefined' && value !== null && value !== 'null' ? value : '';
    }
  }

  private valueToNumber(value: any, col?: DisplayColumn): string {
    const digitsInfo = this.determinateDigitsNumber(value, col);
    if (typeof value === 'number') {
      const pipe = new DecimalPipe('fr-FR');
      return pipe.transform(value, digitsInfo) || '';
    } else {
      return value;
    }
  }

  private determinateDigitsNumber(value: any, col?: DisplayColumn) {
    let digitsInfo = col?.cellTypeOptions || '1.0-0';
    const naturalNumber = new RegExp('^(0|[1-9]\\d*)(e-?(0|[1-9]\\d*))?$');
    if (naturalNumber.test(value + '')) {
      digitsInfo = '1.0-0';
    }
    return digitsInfo;
  }

  private toPercent(value: any, col: DisplayColumn) {
    return new PercentPipe('fr-FR').transform(value, col?.cellTypeOptions) || '';
  }
}
