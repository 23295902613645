<header class="ds-header ds-header--back-office">
  <section class="ds-header__logo" (click)="onGoToAccueil()">
    <img src="/assets/images/client-logo-header.svg" alt="Bpifrance. Servir l'avenir" />
  </section>
  <section id="go-homepage" class="ds-header__signature">
    <div class="text_picxel">PICXEL</div>
  </section>
  <section id="go-homepage-mobile" class="ds-header__signature">
    <div class="text_picxel">PICXEL</div>
  </section>
  <section class="header__actions" [class.header__actions--login]="!userData">
    <lib-alerte-toggle></lib-alerte-toggle>
    <div class="user" *ngIf="userData">
      <div class="user__infos">
        <div class="user__name">
          {{ userData.firstName }} <span class="user__lastname">{{ userData.lastName }}</span>
        </div>
        <div class="user__email">{{ userData.emailAddress }}</div>
      </div>

      <span class="popover_btn" mat-raised-button placement="bottom-right" [ngbPopover]="popHeader">
        <div class="user__avatar" aria-hidden="true">{{ userData.firstName?.substring(0, 1) }}{{ userData.lastName?.substring(0, 1) }}</div>
      </span>
      <ng-template #popHeader>
        <div class="profil_bpi" *ngIf="isMcb">
          <span class="logout_link" (click)="monProfilBpi()">
            <lib-pxl-icon class="icon" icon="icon-profil" aria-hidden="true"></lib-pxl-icon>
            Mon profil de connexion Bpifrance
          </span>
        </div>
        <div style="width: 250px">
          <span class="logout_link" (click)="deconnexion()">
            <lib-pxl-icon class="icon" icon="icon-action-logout" aria-hidden="true"></lib-pxl-icon>
            Déconnexion
          </span>
        </div>
      </ng-template>
    </div>
  </section>
</header>
