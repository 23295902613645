@if (utilisateur) {
<lib-alertes-publiees [positionnement]="EnumPositionnement.RECHERCHE_TRANSVERSE" [utilisateur]="utilisateur"></lib-alertes-publiees>
}

<lib-page-header class="header" [title]="'Recherche transverse'">
  <div *ngIf="isSiteEval" right-elements>
    <ng-container *ngIf="isDoubleProfile">
      <button id="go-to-choice" class="ds-btn ds-btn--secondary me-2" (click)="onClickGoToChoice()">
        Retour au choix du parcours évaluateur
      </button>
    </ng-container>
    <ng-container>
      <button id="switch-to-evaluateur" class="ds-btn ds-btn--secondary" (click)="redirectToCandidats()">
        Accéder à mon espace candidat
      </button>
    </ng-container>
  </div>
</lib-page-header>

<div class="page-content--no-scroll filter_table_content">
  <div id="dstab" class="ds-tabs ds-tabs--underline">
    <ul class="ds-tabs-list" role="tablist" aria-label="nom du système d'onglet">
      <li>
        <button
          id="tab_a"
          class="ds-tabs-list__tab ds-tabs-list__tab--active"
          role="tab"
          tabindex="0"
          aria-controls="panela"
          aria-selected="true"
        >
          <span>Recherche guidée</span>
        </button>
      </li>
      <li>
        <button id="tab_b" class="ds-tabs-list__tab" role="tab" tabindex="1" aria-controls="panelb" aria-selected="false">
          <span>Exploration libre</span>
        </button>
      </li>
    </ul>

    <!-- Recherche guidée -->
    <div tabindex="0" role="tabpanel" id="panela" class="ds-tabs-panel" style="overflow: unset" aria-labelledby="tab_a">
      <lib-recherche-transverse-tab-content
        *ngIf="tableConf && filtersConfig"
        (displayedColumnsChange)="updatePreferences($event)"
        tab="RECHERCHE_GUIDEE"
        [displayedColumns]="displayedColumns"
        [tableConf]="tableConf"
        [filtersConfig]="filtersConfig"
      ></lib-recherche-transverse-tab-content>
    </div>

    <!-- Exploration libre -->
    <div tabindex="0" role="tabpanel" id="panelb" class="ds-tabs-panel" style="overflow: unset" aria-labelledby="tab_b" hidden="">
      <lib-recherche-transverse-tab-content
        *ngIf="tableConf && filtersConfig"
        (displayedColumnsChange)="updatePreferences($event)"
        tab="RECHERCHE_LIBRE"
        [displayedColumns]="displayedColumns"
        [tableConf]="tableConf"
        [filtersConfig]="filtersConfig"
      ></lib-recherche-transverse-tab-content>
    </div>
  </div>
</div>
