import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  buildEtapeCleTitle,
  Contact,
  DemandeCorrection,
  DocumentDemandeModification,
  DocumentFileManagerService,
  EnumRoleStructure,
  EnumScanDocument,
  EnumScope,
  EnumStatutDemandeCorrection,
  EnumStatutDemandeCorrectionTech,
  EnumTypeDocument,
  ShowToastrService,
  SuiviProjet,
} from '@shared-ui';
import { take } from 'rxjs';

@Component({
  selector: 'lib-demande-modification-container',
  templateUrl: './demande-modification-container.component.html',
  styleUrls: ['./demande-modification-container.component.scss'],
})
export class DemandeModificationContainerComponent implements OnInit {
  @Input() demande: DemandeCorrection;
  @Input() etapeCleStatut: string;
  @Input() isArchived: boolean;
  @Input() suiviProjet: SuiviProjet;
  @Output() updateStatutCorrectionEmitter = new EventEmitter();

  contactsText: string;
  structuresText: string;
  etapeCleLabel: string | undefined;

  readMoreContacts = false;
  readMoreStructures = false;
  isLongContacts = false;
  isLongStructures = false;

  readonly MAX_TEXT_LENGTH = 250;
  readonly VALIDE = EnumStatutDemandeCorrectionTech.VALIDE;
  readonly REJETE = EnumStatutDemandeCorrectionTech.REJETE;
  readonly CORRIGE = EnumStatutDemandeCorrectionTech.CORRIGE;
  readonly CORRECTION_REJETE_LABEL = EnumStatutDemandeCorrection.REJETE.toString();
  readonly CORRECTION_VALIDE_LABEL = EnumStatutDemandeCorrection.VALIDE.toString();

  constructor(public documentFileManagerService: DocumentFileManagerService, public showToastrService: ShowToastrService) {}

  ngOnInit(): void {
    this.contactsText =
      this.demande.contactsANotifier
        ?.map((contact: Contact) => (contact.nom ? `${contact.prenom} ${contact.nom}` : contact.email))
        .join(', ') ?? '';
    this.isLongContacts = this.contactsText.length > this.MAX_TEXT_LENGTH;
    this.structuresText =
      this.demande.structures?.map(structure => `${structure.raisonSocial} (${EnumRoleStructure.toString(structure.role)})`).join(', ') ??
      '';
    this.isLongStructures = this.structuresText.length > this.MAX_TEXT_LENGTH;
    const etapeCle = this.suiviProjet?.etapesCle.find(ec => ec.id === this.demande.etapeCleId);
    this.etapeCleLabel = buildEtapeCleTitle(etapeCle, this.suiviProjet?.etapesCle);
  }

  getSentToContactsText(): string {
    return !this.readMoreContacts && this.isLongContacts ? this.contactsText.slice(0, this.MAX_TEXT_LENGTH) + '...' : this.contactsText;
  }

  getSentToStructuresText(): string {
    return !this.readMoreStructures && this.isLongStructures
      ? this.structuresText.slice(0, this.MAX_TEXT_LENGTH) + '...'
      : this.structuresText;
  }

  /*
   * Vérifie si le document uploadé a passé le test antivirus
   * */
  isValidDocument(document: DocumentDemandeModification): boolean {
    if (document?.scan === EnumScanDocument.SAFE) {
      return true;
    }
    return false;
  }

  isScannedDocument(document: DocumentDemandeModification): boolean {
    if (document?.scan === EnumScanDocument.SAFE || document?.scan === EnumScanDocument.UNSAFE) {
      return true;
    }
    return false;
  }

  downloadDocument(document: DocumentDemandeModification): void {
    if (this.isValidDocument(document) && this.isScannedDocument(document)) {
      this.documentFileManagerService.downloadDocument(this.buildMinimalDocumentProjet(document)).pipe(take(1)).subscribe();
    }
  }

  buildMinimalDocumentProjet(document: DocumentDemandeModification): DocumentDemandeModification {
    return {
      ...document,
      typeDoc: EnumTypeDocument.DEMANDE_CORRECTION,
      projetId: this.demande?.projetId,
      scope: EnumScope.PROJET,
    };
  }

  getCorrectionStatus(demande: DemandeCorrection): string {
    return (EnumStatutDemandeCorrection as any)[demande.statut];
  }

  afficherPlusContacts(): void {
    this.readMoreContacts = !this.readMoreContacts;
  }

  afficherPlusStructures(): void {
    this.readMoreStructures = !this.readMoreStructures;
  }

  protected readonly EnumScope = EnumScope;
}
