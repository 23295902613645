import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import {
  Aap,
  AlerteControlService,
  EnumPermissionUtilisateur,
  EnumPositionnement,
  MessageAlerte,
  MessageAlerteHttpService,
  PermissionUtils,
  Projet,
  Utilisateur,
} from '@shared-ui';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter, Observable, of, tap } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Component({
  selector: 'lib-alertes-publiees',
  templateUrl: './alertes-publiees.component.html',
  styleUrl: './alertes-publiees.component.css',
})
export class AlertesPublieesComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);

  @Input({ required: true }) positionnement: EnumPositionnement;
  @Input() utilisateur?: Utilisateur;
  @Input() aap?: Aap;
  @Input() projet?: Projet;
  displayed = false;
  alertes: MessageAlerte[] = [];

  constructor(private alerteControlService: AlerteControlService, private messageAlerteHttpService: MessageAlerteHttpService) {}

  ngOnInit(): void {
    this.alerteControlService.silenced$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(silenced => {
          if (silenced) this.displayed = false;
        }),
        filter(silenced => !silenced),
        mergeMap(() => this.loadAlertes())
      )
      .subscribe(alertes => {
        const silencedIds = this.alerteControlService.getSilencedAlerteIds();
        this.alertes = alertes.filter(a => !silencedIds.includes(a.id));
        this.displayed = true;
      });
  }

  loadAlertes(): Observable<MessageAlerte[]> {
    if (this.positionnement == EnumPositionnement.ACCUEIL_NC) {
      return this.messageAlerteHttpService.getAlertesPublieesForPublic(this.positionnement);
    }
    if (!this.utilisateur) {
      return of([]);
    }
    if (PermissionUtils.hasPermission(this.utilisateur, EnumPermissionUtilisateur.MESSAGE_ALERTE_CANDIDAT_READ)) {
      return this.messageAlerteHttpService.getAlertesPublieesForCandidat(this.positionnement);
    }
    if (PermissionUtils.hasPermission(this.utilisateur, EnumPermissionUtilisateur.MESSAGE_ALERTE_EVALUATEUR_READ)) {
      return this.messageAlerteHttpService.getAlertesPublieesForEvaluateur(
        this.positionnement,
        this.projet?.aap.id ?? this.aap?.id,
        this.projet?.id
      );
    }
    if (PermissionUtils.hasPermission(this.utilisateur, EnumPermissionUtilisateur.MESSAGE_ALERTE_INTERNE_READ)) {
      return this.messageAlerteHttpService.getAlertesPublieesForInterne(this.positionnement);
    }
    return of([]);
  }

  closeAlerte(alerte: MessageAlerte) {
    this.alerteControlService.addSilencedAlerte(alerte);
    this.alertes = this.alertes.filter(a => a.id !== alerte.id);
  }
}
