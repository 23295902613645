<button [matMenuTriggerFor]="menu" class="ds-btn ds-btn--secondary">{{ label }}</button>
<mat-menu #menu="matMenu" (closed)="onMenuClosed()">
  <div
    class="custom-filters-container"
    (click)="$event.stopPropagation()"
    (keydown.tab)="$event.stopPropagation()"
    (keydown.shift.tab)="$event.stopPropagation()"
    cdkTrapFocus
    [cdkTrapFocusAutoCapture]="true"
  >
    @for (filtre of filters; track filtre.source) {
    <div class="single-filter-container">
      <span class="ds-input-group__label">{{ filtre.label }}</span>
      @if (filtre.type === FilterType.RANGE) {
      <div class="range-filters-container">
        <div
          id="input-min"
          class="form-step__total ds-input-group range-filter"
          [class.ds-input-group--error]="filtre.min < 0 || filtre.min > filtre.max"
        >
          <span class="ds-input-group__input">
            <input
              id="min"
              name="min"
              type="number"
              placeholder="Minimum"
              min="0"
              (keypress)="rechercheTransverseFiltersService.preventValues($event)"
              class="ds-input-group__input"
              [(ngModel)]="filtre.min"
            />
          </span>
        </div>
        <div
          id="input-max"
          class="form-step__total ds-input-group range-filter"
          [class.ds-input-group--error]="filtre.max < 0 || filtre.min > filtre.max"
        >
          <span class="ds-input-group__input">
            <input
              id="max"
              name="max"
              type="number"
              (keypress)="rechercheTransverseFiltersService.preventValues($event)"
              min="0"
              placeholder="Maximum"
              class="ds-input-group__input"
              [(ngModel)]="filtre.max"
            />
          </span>
        </div>
      </div>
      <!-- message d'erreur -->
      <ng-container id="error-budget" *ngIf="filtre.min > filtre.max">
        <div class="ds-input-group__feedback">Le minimum doit être inférieur ou égal au maximum</div>
      </ng-container>
      } @else if (filtre.type === FilterType.MULTI_SELECT_AUTO_COMPLETE) {
      <lib-custom-multiselect
        (autocompleteEvent)="rechercheTransverseFiltersService.autocomplete($event, filtre)"
        (selectedListChange)="rechercheTransverseFiltersService.onSelectionChange($event, filtre)"
        [(dataList)]="filtre.dataList!"
        [selectedList]="filtre.selectedList!"
        [placeholder]="filtre.label"
        [name]="filtre.source"
        class="filter"
        [isLoading]="loadingMap.get(filtre.source)"
        [autocompleteStart]="1"
      >
      </lib-custom-multiselect>
      } @else if (filtre.type === FilterType.OUI_NON_RADIO_BUTTON) {
      <div class="select-container d-flex align-items-center">
        <div class="oui-non-radio-button-container">
          <input
            type="radio"
            name="{{ filtre.source }}-oui"
            id="{{ filtre.source }}-oui"
            class="ds-radio-group__radio-input"
            value="OUI"
            [(ngModel)]="filtre.selectedValue"
            hidden
          />
          <label for="{{ filtre.source }}-oui" class="ds-radio-group__radio-label">Oui</label>

          <input
            type="radio"
            name="{{ filtre.source }}-non"
            id="{{ filtre.source }}-non"
            class="ds-radio-group__radio-input"
            value="NON"
            [(ngModel)]="filtre.selectedValue"
            hidden
          />
          <label for="{{ filtre.source }}-non" class="ds-radio-group__radio-label">Non</label>
        </div>
      </div>
      } @else if (filtre.type === FilterType.MULTI_SELECT) {
      <lib-ds-multiselect
        *ngIf="!loadingMap.get(filtre.source)"
        class="filter"
        [dataList]="filtre.dataList!"
        [selectOptions]="{ placeholder: filtre.label }"
        [name]="filtre.source"
      >
      </lib-ds-multiselect>
      } @else if (filtre.type === FilterType.SIMPLE_INPUT) {
      <div class="filter-container">
        <input
          class="filter-input"
          type="text"
          name="{{ filtre.source }}"
          id="{{ filtre.source }}"
          placeholder="Description courte"
          [(ngModel)]="filtre.simpleInputValue"
        />
      </div>
      } @else if (filtre.type === FilterType.RANGE_DATE) {
      <lib-date-range-picker
        [id]="filtre.source"
        (selectionChange)="rechercheTransverseFiltersService.onDateRangeChange($event, filtre)"
      ></lib-date-range-picker>
      }
    </div>
    }

    <div class="filters-actions">
      <button id="cancel-btn" class="ds-btn ds-btn--tertiary" (click)="resetFilters($event)">Réinitialiser</button>

      <button id="save-btn" class="ds-btn ds-btn--primary" (click)="applyFilters()">Appliquer</button>
    </div>
  </div>
</mat-menu>
