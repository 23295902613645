import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'lib-table-dialog-duplicate',
  templateUrl: './table-dialog-duplicate.component.html',
  styleUrls: ['./table-dialog-duplicate.component.scss'],
})
export class TableDialogDuplicateComponent {
  duplicateForm: FormGroup;
  displayCoefficient = false;

  constructor(
    public dialogRef: MatDialogRef<TableDialogDuplicateComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private fb: FormBuilder
  ) {
    if (modalData.boldContent === undefined) {
      modalData.boldContent = true;
    }
    this.duplicateForm = this.fb.group({});
  }

  isDisplayCoefficient(): void {
    this.displayCoefficient = !this.displayCoefficient;
    if (this.displayCoefficient) {
      this.duplicateForm.addControl('coefficient', new FormControl(null, Validators.required));
    } else {
      this.duplicateForm.removeControl('coefficient');
    }
  }

  onSubmit(): void {
    if (this.duplicateForm.valid) {
      this.dialogRef.close({
        confirm: true,
        coefficient: this.duplicateForm.get('coefficient')?.value,
      });
    }
  }

  onkeypress($event: KeyboardEvent): boolean {
    if ($event.key) {
      if (/^[0-9.]+$/.test($event.key)) {
        return true;
      }
    }
    return false;
  }
}
