import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { MAT_MENU_DEFAULT_OPTIONS, MatMenuTrigger } from '@angular/material/menu';
import { DateRangePickerComponent, DsMultiselectComponent, FiltersCriteria, FilterType, MultiselectComponent } from '@shared-ui';
import { FilterConfig } from '../recherche-transverse/recherche-transverse.config';
import { RechercheTransverseFiltersService } from '../../../shared/services/recherche-transverse-filters.service';

declare const cact: any;
@Component({
  selector: 'lib-recherche-transverse-filters',
  templateUrl: './recherche-transverse-filters.component.html',
  styleUrl: './recherche-transverse-filters.component.scss',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: MAT_MENU_DEFAULT_OPTIONS,
      useValue: {
        overlayPanelClass: 'filters-menu',
      },
    },
  ],
})
export class RechercheTransverseFiltersComponent implements OnInit {
  @Input() label!: string;
  @Input() filters!: FilterConfig[];
  @Output() filtersChanged: EventEmitter<FiltersCriteria> = new EventEmitter();

  @ViewChild(MatMenuTrigger) trigger!: MatMenuTrigger;
  @ViewChildren(forwardRef(() => MultiselectComponent)) multiselectComponents!: QueryList<MultiselectComponent>;
  @ViewChildren(forwardRef(() => DsMultiselectComponent)) dsMultiselectComponents!: QueryList<DsMultiselectComponent>;
  @ViewChildren(forwardRef(() => DateRangePickerComponent)) dateRangePickerComponents!: QueryList<DateRangePickerComponent>;

  isOpened = false;
  loadingMap = new Map();

  constructor(public rechercheTransverseFiltersService: RechercheTransverseFiltersService) {}

  ngOnInit(): void {
    this.filters
      .filter(filter => filter.type === FilterType.MULTI_SELECT_AUTO_COMPLETE)
      .forEach(filter => {
        this.loadingMap.set(filter.source, false);
      });
  }

  onMenuClosed(): void {
    if (this.isOpened) {
      this.isOpened = false;
      this.applyFilters();
    }
  }

  applyFilters(): void {
    const filtersValues = this.rechercheTransverseFiltersService.getFiltersValues(this.filters);

    const dataToSend: FiltersCriteria = {
      rangeFilters: filtersValues.rangeFilters,
      selectFilters: filtersValues.selectFilters,
      rangeDatesCriterias: filtersValues.rangeDatesCriterias,
      objetProjet: filtersValues.inputValue,
    };
    this.filtersChanged.emit(dataToSend);

    if (this.isOpened) {
      this.trigger.closeMenu();
    }
  }

  resetFilters(event?: Event) {
    this.dateRangePickerComponents.forEach(dateRangePickerComponent => {
      dateRangePickerComponent.reset();
    });
    this.multiselectComponents.forEach(multiselectComponent => {
      multiselectComponent?.reset();
    });
    this.dsMultiselectComponents.forEach(dsMultiselectComponent => {
      dsMultiselectComponent?.reset();
    });
    this.rechercheTransverseFiltersService.resetFilters(this.filters);

    cact('emit', 'search', {
      from: event?.target,
      eventAction: 'Reinit',
    });
  }

  protected readonly FilterType = FilterType;
}
