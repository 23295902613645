<div class="filters-container">
  <ng-container *ngIf="tab === RECHERCHE_GUIDEE">
    <lib-recherche-guidee-filters #basicFilters> </lib-recherche-guidee-filters>
  </ng-container>
  <ng-container *ngIf="tab === RECHERCHE_LIBRE">
    <div class="filter-container">
      <input
        type="text"
        class="filter-input"
        placeholder='"Exemple de mot clef à rechercher", "projet Algue"'
        [(ngModel)]="allFiltersValues.freeSearchQuery"
      />
    </div>
  </ng-container>
  <div class="search-buttons-container">
    <button type="button" class="ds-btn ds-btn--secondary" (click)="resetAllFilters($event)">
      <span class="ds-icon ds-icon-left ds-float--left" aria-hidden="true">refresh</span>
      Réinitialiser
    </button>
    <button id="actions-btn" class="ds-btn ds-btn--primary" (click)="searchData($event)">Rechercher</button>
  </div>
</div>
<div class="table-section" *ngIf="dataLoaded">
  <div class="table-header">
    <span class="section-subtitle">
      <b>{{ dataSource?.data?.length }} résultats</b> à votre recherche {{ tab === RECHERCHE_GUIDEE ? 'guidée' : 'libre' }}
    </span>
    <div class="table-action-btn">
      <div class="additionnal-filter" *ngIf="filtersConfig">
        <lib-recherche-transverse-filters
          *ngIf="filtersConfig.dateFilters?.length"
          label="Date"
          [filters]="filtersConfig.dateFilters"
          (filtersChanged)="loadFilters($event)"
        ></lib-recherche-transverse-filters>
        <lib-recherche-transverse-filters
          *ngIf="filtersConfig.projetFilters?.length"
          label="Projet"
          [filters]="filtersConfig.projetFilters"
          (filtersChanged)="loadFilters($event)"
        ></lib-recherche-transverse-filters>
        <lib-recherche-transverse-filters
          *ngIf="filtersConfig.interneFilters?.length"
          label="Interne BpiFrance"
          [filters]="filtersConfig.interneFilters"
          (filtersChanged)="loadFilters($event)"
        ></lib-recherche-transverse-filters>
        <lib-recherche-transverse-filters
          *ngIf="filtersConfig.partenaireFilters?.length"
          label="Partenaire"
          [filters]="filtersConfig.partenaireFilters"
          (filtersChanged)="loadFilters($event)"
        ></lib-recherche-transverse-filters>
        <lib-recherche-transverse-filters
          *ngIf="filtersConfig.partenaireContractualisationFilters?.length"
          label="Partenaire - Contractualisation"
          [filters]="filtersConfig.partenaireContractualisationFilters"
          (filtersChanged)="loadFilters($event)"
        ></lib-recherche-transverse-filters>
      </div>
      <div class="d-flex align-items-center">
        <button class="ds-btn ds-btn--tertiary" (click)="exportData()">Exporter le résultat</button>
        <button type="button" class="ds-btn ds-btn--secondary ms-2" (click)="modifierColonnes()">
          <lib-pxl-icon class="icon" icon="icon-view-column"></lib-pxl-icon>
          Modifier le tableau
        </button>
      </div>
    </div>
  </div>

  <div class="table-container">
    <table class="recherche-guidee-table" mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="select">
        <th id="projets-select" mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
          >
          </mat-checkbox>
        </th>
        <td id="id" mat-cell *matCellDef="let element">
          <mat-checkbox (change)="$event ? selection.toggle(element) : null" [checked]="selection.isSelected(element)"> </mat-checkbox>
        </td>
      </ng-container>
      <ng-container *ngFor="let conf of tableConf" [matColumnDef]="conf.id">
        <th [id]="conf.id" mat-header-cell *matHeaderCellDef mat-sort-header>{{ conf.label }}</th>
        <td
          [id]="conf.id"
          mat-cell
          *matCellDef="let element"
          [matTooltipDisabled]="element[conf.id]?.length <= CELL_MAX_LENGTH || conf.type === 'number'"
          [matTooltip]="element[conf.id]"
          [style.max-width.ch]="CELL_MAX_LENGTH"
        >
          {{ element[conf.id] | formatText : conf.type }}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr id="go-projet" mat-row class="r" *matRowDef="let row; columns: displayedColumns" style="cursor: pointer"></tr>
    </table>
    <div class="no-result" *ngIf="dataSource?.data?.length === 0">
      <img src="assets/images/no_projects.svg" alt="Pas de résultats" />
      <span id="small-span-result">vous n'avez actuellement aucune donnée</span>
    </div>
  </div>
</div>
