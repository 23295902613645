<table
  *ngIf="dataSource"
  mat-table
  [dataSource]="dataSource"
  multiTemplateDataRows
  class="table-responsive mat-elevation-z0"
  [class.responsive]="tableReponsive"
>
  <ng-container *ngFor="let col of cols; let colIndex = index" [matColumnDef]="col.def" [sticky]="col.sticky">
    <th mat-header-cell *matHeaderCellDef class="column-key" [ngStyle]="{ 'max-width': col.width, 'min-width': col.width }">
      <div [matTooltip]="col.tooltip ? col.tooltip : ''" matTooltipClass="tooltip-multiline" class="d-inline" style="vertical-align: sub">
        <span> {{ col.label }} </span>
      </div>
      <lib-asterisk *ngIf="col.mandatory" />
      <div *ngIf="col.expandAction" class="d-inline me-2">
        <button
          type="button"
          id="header-expand-element"
          class="card__action-button"
          (click)="toggleAllRowsExpand()"
          style="float: inline-start"
        >
          <lib-pxl-icon *ngIf="!allRowsExpanded" class="icon" icon="icon-action-add-circle-outline"></lib-pxl-icon>
          <lib-pxl-icon *ngIf="allRowsExpanded" class="icon" icon="icon-action-minus-circle-outline"></lib-pxl-icon>
        </button>
      </div>
    </th>
    <td
      mat-cell
      *matCellDef="let element; let i = dataIndex"
      (click)="col.editable || col.selectable || col.datePicker ? (element[col.def].editable = true) : null; cellSelected(col, i)"
      [ngStyle]="{ 'background-color': getBackgroundColor(element, col), 'max-width': col.width, 'min-width': col.width }"
    >
      <div style="display: flex; justify-content: space-between">
        <span
          *ngIf="
            (disable === 'DISABLED' ? true : false) ||
            (!col.editable && !col.selectable && !col.datePicker) ||
            !element[col.def]?.editable ||
            !element
          "
        >
          {{
            element[col.def]?.value !== '' && col.date
              ? (element[col.def]?.value | date : 'dd/MM/yyyy')
              : col.def === 'id'
              ? i + 1
              : (element[col.def]?.value | formatNumber : col)
          }}
        </span>
        <div
          *ngIf="
            element[col.def]?.editable && (col.editable || col.selectable || col.datePicker) && (disable === 'DISABLED' ? false : true)
          "
        >
          <input
            *ngIf="col.editable"
            [id]="col.def"
            [name]="col.def"
            [disabled]="disable === 'DISABLED' ? true : false"
            [placeholder]="col.placeholder ?? ''"
            [(ngModel)]="element[col.def].value"
            (change)="onDataChange(element)"
            (keydown)="onKeyPress($event, element)"
            (blur)="element[col.def].editable = false"
            type="text"
            libAutoFocus
            class="input-cell ds-input-group__input"
          />
          <select
            *ngIf="col.selectable"
            [id]="col.selectable.id"
            [name]="col.selectable.name"
            [(ngModel)]="element[col.def].value"
            (change)="onDataChange(element)"
            (blur)="element[col.def].editable = false"
            libAutoFocus
          >
            <option [ngValue]="null" selected disabled hidden>{{ col.selectable.placeholder }}</option>
            <option *ngFor="let option of col.selectable.options" [value]="option">{{ option }}</option>
          </select>
          <span class="ds-input-group__input" *ngIf="col.datePicker">
            <input
              [id]="col.def"
              [name]="col.def"
              [(ngModel)]="element[col.def].value"
              matInput
              [matDatepicker]="openDatePicker"
              [disabled]="disable === 'DISABLED' ? true : false"
              (keydown)="onKeyPress($event, element, col.datePicker)"
              (click)="openDatePicker.open()"
              (dateChange)="onDateChange(element)"
              class="ds-input-group__input"
              placeholder="JJ/MM/AAAA"
              libAutoFocus
            />
            <mat-datepicker #openDatePicker></mat-datepicker>
          </span>
        </div>
        <div
          *ngIf="col.deleteAction"
          [matTooltip]="col.tooltipAction ? col.tooltipAction : ''"
          matTooltipClass="tooltip-multiline"
          class="d-inline me-2"
        >
          <button
            type="button"
            id="delete-element"
            class="card__action-button"
            (click)="onDeleteElement(i)"
            [hidden]="disable === 'DISABLED' ? true : false"
          >
            <lib-pxl-icon class="icon" icon="icon-action-delete"></lib-pxl-icon>
          </button>
        </div>
        <div *ngIf="col.expandAction" class="d-inline me-2">
          <button
            type="button"
            id="expand-element"
            class="card__action-button"
            [disabled]="
              (tableExpandableArSub && element['ecLength']?.value === 0) ||
              (tableExpandableTable && element['remboursementArlength']?.value === 0) ||
              (tableExpandableAnnexe && element['natureDepenseLength']?.value === 0)
            "
            (click)="expandOneRow(element); tableExpandableArSub ? rowEC(element) : tableExpandableTable ? rowRegime() : rowAnnexe()"
          >
            <lib-pxl-icon *ngIf="!expandedElement.includes(element)" class="icon" icon="icon-action-add-circle-outline"></lib-pxl-icon>
            <lib-pxl-icon *ngIf="expandedElement.includes(element)" class="icon" icon="icon-action-minus-circle-outline"></lib-pxl-icon>
          </button>
        </div>
        <div
          class="merge-input-note"
          *ngIf="(element[col.def] && element[col.def]?.note) || (element[col.def] && element[col.def]?.metaData?.commentaire?.data)"
        >
          <lib-pxl-icon class="icon-info" icon="icon-notes" />
        </div>
      </div>
    </td>
    <td mat-footer-cell *matFooterCellDef>
      <span *ngIf="colIndex === 0"> {{ 'Total' }}</span>
      <span *ngIf="col.total"> {{ getTotalCost(col) | number : '1.2-2' }} </span>
    </td>
  </ng-container>

  <ng-container matColumnDef="expandedDetail" *ngIf="tableExpandableArSub">
    <td class="border" mat-cell *matCellDef="let element; let j = dataIndex" [attr.colspan]="displayedColumnslength">
      <div [@detailExpand]="isExpandedOrCollapsed(element)">
        <table>
          <tr>
            <td class="border cellWidth stickyWidth">
              <div class="expand-first-cell">
                <span class="fw-bold">SUB</span>
                <span *ngIf="element.forcageSub" class="forcage"> forcé </span>
              </div>
            </td>
            <td *ngIf="element.regime" class="border cellWidth stickyWidth">{{ element.regime?.value }}</td>
            <td class="border cellWidth">{{ element.aideSub?.value | number : '1.2-2' }}</td>
            <td class="border cellWidth" [ngStyle]="{ 'background-color': getBackgroundColorExpand(element, 'aideT0Sub') }">
              {{ element.aideT0Sub?.value | number : '1.2-2' }}
            </td>
            <ng-container *ngIf="element.regime; else noRegimeSub">
              <ng-container
                *ngFor="let sub of rowEcArSub.get(element.idStructure.value + '_' + element.regime.value + '_ecSub'); let i = index"
              >
                <td class="border cellWidth" [ngStyle]="{ 'background-color': getBackgroundColorExpand(element, 'ECSUB_' + i) }">
                  {{ sub | number : '1.2-2' }}
                </td>
              </ng-container>
            </ng-container>
            <ng-template #noRegimeSub>
              <ng-container *ngFor="let sub of rowEcArSub.get(element.idStructure.value + '_ecSub'); let i = index">
                <td class="border cellWidth" [ngStyle]="{ 'background-color': getBackgroundColorExpand(element, 'ECSUB_' + i) }">
                  {{ sub | number : '1.2-2' }}
                </td>
              </ng-container>
            </ng-template>
            <td *ngIf="containsAction" class="border cellWidthAction">
              <div class="d-flex justify-content-center" *ngIf="disable !== 'DISABLED'">
                <div matTooltip="zone de forcage" matTooltipClass="tooltip-multiline" class="d-inline">
                  <button type="button" id="forcing-sub" class="card__action-button" (click)="onZoneForcage(element, 'SUB')">
                    <lib-pxl-icon class="icon" icon="icon-action-create"></lib-pxl-icon>
                  </button>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td class="border cellWidth fw-bold stickyWidth">
              <div class="expand-first-cell">
                <span class="fw-bold">AR</span>
                <span *ngIf="element.forcageAr" class="forcage"> forcé </span>
              </div>
            </td>
            <td *ngIf="element.regime" class="border cellWidth stickyWidth">{{ element.regime?.value }}</td>
            <td class="border cellWidth">{{ element.aideAr?.value | number : '1.2-2' }}</td>
            <td class="border cellWidth" [ngStyle]="{ 'background-color': getBackgroundColorExpand(element, 'aideT0Ar') }">
              {{ element.aideT0Ar?.value | number : '1.2-2' }}
            </td>
            <ng-container *ngIf="element.regime; else noRegimeAr">
              <ng-container
                *ngFor="let ar of rowEcArSub.get(element.idStructure.value + '_' + element.regime.value + '_ecAr'); let i = index"
              >
                <td class="border cellWidth" [ngStyle]="{ 'background-color': getBackgroundColorExpand(element, 'ECAR_' + i) }">
                  {{ ar | number : '1.2-2' }}
                </td>
              </ng-container>
            </ng-container>
            <ng-template #noRegimeAr>
              <ng-container *ngFor="let ar of rowEcArSub.get(element.idStructure.value + '_ecAr'); let i = index">
                <td class="border cellWidth" [ngStyle]="{ 'background-color': getBackgroundColorExpand(element, 'ECAR_' + i) }">
                  {{ ar | number : '1.2-2' }}
                </td>
              </ng-container>
            </ng-template>
            <td *ngIf="containsAction" class="border cellWidthAction">
              <div class="d-flex justify-content-center" *ngIf="disable !== 'DISABLED'">
                <div matTooltip="zone de forcage" matTooltipClass="tooltip-multiline" class="d-inline">
                  <button type="button" id="forcing-ar" class="card__action-button" (click)="onZoneForcage(element, 'AR')">
                    <lib-pxl-icon class="icon" icon="icon-action-create"></lib-pxl-icon>
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </td>
  </ng-container>

  <ng-container *ngIf="tableExpandableTable" matColumnDef="expandedDetail">
    <td class="border" mat-cell *matCellDef="let element; let j = dataIndex" [attr.colspan]="displayedColumnslength">
      <div [@detailExpand]="isExpandedOrCollapsed(element)" class="d-flex">
        <table>
          <ng-container *ngFor="let element2 of arrayRegime; let i = index">
            <tr *ngIf="element2.idStructure === element.idStructure.value">
              <td class="border cellWidth fw-bold stickyWidth">{{ element2.regime.regime }}</td>
              <td class="border cellWidth">{{ element2.regime.remboursementAr.montantTotal | number : '1.2-2' }}</td>
              <ng-container *ngFor="let annee of element2.regime.remboursementAr.montantsAnnees">
                <td class="border cellWidth">{{ annee.montant | number : '1.2-2' }}</td>
              </ng-container>
              <td class="border cellWidth">{{ element2.regime.remboursementAr.ajustementPrevisionnelTotal | number : '1.2-2' }}</td>
              <td class="border cellWidth">{{ element2.regime.remboursementAr.vaAr | number : '1.2-2' }}</td>
            </tr>
          </ng-container>
        </table>
      </div>
    </td>
  </ng-container>

  <ng-container *ngIf="tableExpandableAnnexe" matColumnDef="expandedDetail">
    <td class="border" mat-cell *matCellDef="let element; let j = dataIndex" [attr.colspan]="displayedColumnslength">
      <div [@detailExpand]="isExpandedOrCollapsed(element)">
        <table>
          <ng-container *ngFor="let element2 of arrayAnnexe; let i = index">
            <tr *ngIf="element2.idStructure === element.idStructure.value">
              <td class="border cellWidth stickyWidthAnnexe"></td>
              <td class="border cellWidth">{{ element2.annexesFinanciers.natureDepense }}</td>
              <td class="border cellWidth">{{ element2.annexesFinanciers.total | number : '1.0-0' }}</td>
              <ng-container *ngFor="let ec of element2.annexesFinanciers.etapeCleDepenses">
                <td class="border cellWidth">{{ ec.montant | number : '1.0-0' }}</td>
              </ng-container>
            </tr>
          </ng-container>
        </table>
      </div>
    </td>
  </ng-container>

  <ng-container *ngIf="!noHeader">
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  </ng-container>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns; let i = dataIndex"
    [class.expandableRow]="tableExpandableArSub || tableExpandableTable || tableExpandableAnnexe"
  ></tr>
  <ng-container *ngIf="tableExpandableArSub || tableExpandableTable || tableExpandableAnnexe || showTotal">
    <ng-container *ngIf="tableExpandableArSub || tableExpandableTable || tableExpandableAnnexe">
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
    </ng-container>
    <ng-container *ngIf="showTotal">
      <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
    </ng-container>
  </ng-container>
</table>
