<div
  *ngIf="document.typeDoc !== EnumTypeDocument.DOCUMENT_NON_FOURNI"
  class="card card--list-file"
  [ngClass]="{
    'card--warning': isScanedDocument && !isValidDocument
  }"
>
  <div class="card__text">
    <div class="me-2">
      <mat-checkbox
        *ngIf="hasCheckbox"
        [checked]="isChecked"
        [(ngModel)]="isChecked"
        (click)="checkChange()"
        [id]="'checkbox__' + document.id"
      ></mat-checkbox>
    </div>
    <div class="card__text-info">
      <span class="card__doc--name">
        {{ documentName }}
      </span>

      <span id="ficheComPubliqueTag" *ngIf="document.typeDoc === 'FICHE_COMMUNICATION_PUBLIQUE'" class="tag-fc--yellow ms-3">
        Publique
      </span>

      <span id="ficheComInterneTag" *ngIf="document.typeDoc === 'FICHE_COMMUNICATION_INTERNE'" class="tag-fc--yellow ms-3">
        Interne Bpifrance
      </span>

      <span id="nePasCommuniquerFicheComTag" *ngIf="compareDateVar" class="tag-fc--red ms-3"> NE PAS COMMUNIQUER </span>

      <span *ngIf="approbation === 'DOCUMENTS_CONTRAT_APPROUVES'" class="approbation-verified"> Vérifié </span>
      <span *ngIf="approbation === 'DOCUMENTS_CONTRAT_CONTESTES'" class="approbation-contested"> A revoir </span>
      <ng-container *ngIf="showDateAndUploaderName && document.dateCreation">
        <br />
        Chargé le {{ document.dateCreation | date : 'dd/MM/yyyy' }} {{ nomCreateur }}
      </ng-container>
    </div>
  </div>

  <div class="card__actions">
    <ng-content select="[document-actions]"></ng-content>

    <!-- Validation block -->
    @if (validationEnabled) {
    <div class="card__action-button-wrapper">
      <div class="document_checkbox">
        <label class="ds-radio--alternative white_button" [ngClass]="{ 'ds-radio--alternative-success': statut === VALIDE }">
          <input
            class="ds-radio__input"
            type="radio"
            (click)="changeDocumentStatut(VALIDE)"
            [checked]="valideChecked"
            [disabled]="!canUserWrite"
          />
          <span class="ds-radio__label">
            <span>{{ EnumValidation.VALIDE }}</span>
          </span>
        </label>

        <label class="ds-radio--alternative white_button" [ngClass]="{ 'ds-radio--alternative-error': statut === INVALIDE }">
          @if (commentaireDocumentInvalide) {
          <div style="display: flex; justify-content: end">
            <button
              class="card__action-button comment-button invalide-doc"
              matTooltipClass="tooltip"
              type="button"
              (click)="openInvalidateModal()"
              [matTooltipPosition]="'right'"
              [matTooltip]="commentaireDocumentInvalide"
              [disabled]="!canUserWrite"
            >
              <lib-pxl-icon class="icon" icon="icon-comment"></lib-pxl-icon>
            </button>
          </div>
          }
          <div>
            <input
              class="ds-radio__input"
              type="radio"
              (click)="openInvalidateModal()"
              [checked]="invalideChecked"
              [disabled]="!canUserWrite"
            />
            <span class="ds-radio__label" matTooltipClass="tooltip" [matTooltip]="EnumDocumentInvalide.toString(motifDocumentInvalide)">
              {{ EnumValidation.INVALIDE }}
            </span>
          </div>
        </label>
      </div>
    </div>
    }

    <div
      class="card__action-button-wrapper"
      *ngIf="!!moveDocumentConfig && canUserWrite && !isCandidat && !document?.demandeComplementDocumentId"
    >
      <button type="button" id="change-document" class="card__action-button" (click)="onChangeDocument(document)">
        <lib-pxl-icon class="icon" icon="icon-action-create"></lib-pxl-icon>
      </button>
    </div>

    <div class="card__action-button-wrapper">
      <button
        type="button"
        id="download-document"
        class="card__action-button"
        [ngClass]="{
          'card__action-button--error': isScanedDocument && !isValidDocument
        }"
        (click)="downloadDocument(document)"
        [disabled]="!isValidDocument || !isScanedDocument"
        *ngIf="showDownload === undefined || showDownload"
      >
        <lib-pxl-icon class="icon" icon="icon-action-save-alt"></lib-pxl-icon>
      </button>
      <div class="card__action-button-tooltip" *ngIf="!isScanedDocument">
        <p>Le test antimalware est en cours.</p>
      </div>
      <div class="card__action-button-tooltip" *ngIf="isScanedDocument && !isValidDocument">
        <p>Ce fichier a été identifié comme étant potentiellement dangereux et ne peut donc pas être téléchargé.</p>
      </div>
    </div>
    <div class="card__action-button-wrapper" *ngIf="(!isSiteEval && canUserWrite) || (isSiteEval && isUserOwner())">
      <button
        type="button"
        id="delete-document"
        class="card__action-button"
        (click)="onDeleteDocument(document)"
        [disabled]="deleteDisabled"
      >
        <lib-pxl-icon class="icon" icon="icon-action-delete"></lib-pxl-icon>
      </button>
      <div class="card__action-button-tooltip" *ngIf="deleteDisabled && !isSiteAdmin">
        <p>Vous ne pouvez pas supprimer ce fichier à cette étape.</p>
      </div>
    </div>
  </div>
</div>
