import { enumKeys } from '../../utils/enum-utils';

export enum EnumActivite {
  VERROUILLAGE = 'VERROUILLAGE',
  ELIGIBILITE = 'ELIGIBILITE',
  OPPORTUNITE = 'OPPORTUNITE',
  AUDITION = 'AUDITION',
  INSTRUCTION = 'INSTRUCTION',
  CONTRACTUALISATION = 'CONTRACTUALISATION',
  SUIVI = 'SUIVI',
  SUIVI_INSTRUCTEUR = 'SUIVI_INSTRUCTEUR',
  SUIVI_JURIDIQUE = 'SUIVI_JURIDIQUE',
}

export namespace EnumActivite {
  export function toString(activite: EnumActivite): string {
    switch (activite) {
      case EnumActivite.VERROUILLAGE:
        return 'Analyse';
      case EnumActivite.ELIGIBILITE:
        return 'Eligibilité';
      case EnumActivite.OPPORTUNITE:
        return 'Evaluation/ Opportunité';
      case EnumActivite.AUDITION:
        return 'Audition';
      case EnumActivite.INSTRUCTION:
        return 'Instruction';
      case EnumActivite.CONTRACTUALISATION:
        return 'Contractualisation';
      case EnumActivite.SUIVI:
        return 'Suivi';
      case EnumActivite.SUIVI_INSTRUCTEUR:
        return 'Suivi instructeur';
      case EnumActivite.SUIVI_JURIDIQUE:
        return 'Suivi juridique';
    }
  }
  export function fromValue(value: string): EnumActivite | undefined {
    const keys = Object.keys(EnumActivite) as Array<keyof typeof EnumActivite>;
    return keys.find(key => EnumActivite[key] === value) as EnumActivite | undefined;
  }
  export function all(): EnumActivite[] {
    return enumKeys(EnumActivite) as unknown as EnumActivite[];
  }
}

export enum EnumCriteresReferentielOptions {
  OPPORTUNITE = 'OPPORTUNITE',
  AUDITION = 'AUDITION',
}

export namespace EnumCriteresReferentielOptions {
  export function toString(option: EnumCriteresReferentielOptions): string {
    switch (option) {
      case EnumCriteresReferentielOptions.OPPORTUNITE:
        return 'Opportunité';
      case EnumCriteresReferentielOptions.AUDITION:
        return 'Audition';
    }
  }

  export function all(): EnumCriteresReferentielOptions[] {
    return enumKeys(EnumCriteresReferentielOptions) as unknown as EnumCriteresReferentielOptions[];
  }
}
