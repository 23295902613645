import { InformationProjetFicheCom } from './information-projet-fiche-com.model';
import { InformationChefDeFileFicheCom } from './information-chef-de-file-fiche-com.model';
import { ContactPresseFicheCom } from './contact-presse-fiche-com.model';
import { LogoEntrepriseFicheCom } from './logo-entreprise-fiche-com.model';
import { EnumValidation, Valideur } from '@shared-ui';
import { DerogationDatePublicationFicheCom } from './derogation-date-publication-fiche-com.model';

export class FicheCom {
  informationProjet: InformationProjetFicheCom;
  informationChefDeFile: InformationChefDeFileFicheCom;
  contactPresse: ContactPresseFicheCom;
  logoEntreprises: LogoEntrepriseFicheCom[];
  derogationDatePublication: DerogationDatePublicationFicheCom;
  statut: EnumValidation;
  valideur: Valideur;
}
