import { HttpErrorResponse } from '@angular/common/http';
import { Component, DestroyRef, forwardRef, inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FiltersCriteria,
  FilterType,
  FlechageBudgetaireService,
  MultiselectComponent,
  RechercheTransverseFiltersService,
  RechercheTransverseService,
  ShowToastrService,
  StatutProcessFR30,
} from '@shared-ui';
import { FilterConfig } from '../../recherche-transverse/recherche-transverse.config';

@Component({
  selector: 'lib-recherche-guidee-filters',
  templateUrl: './recherche-guidee-filters.component.html',
  styleUrls: ['./recherche-guidee-filters.component.scss'],
})
export class RechercheGuideeFiltersComponent implements OnInit {
  destroyRef = inject(DestroyRef);

  readonly AAP = 'aap';
  readonly DISPOSITIF = 'dispositif';
  readonly ACRONYME = 'acronyme';
  readonly DENOMINATION_SOCIALE = 'denomination_sociale';
  readonly PROCESS_FR30 = 'process_fr30';

  @ViewChildren(forwardRef(() => MultiselectComponent)) multiselectComponents!: QueryList<MultiselectComponent>;

  dispositifFilter: FilterConfig;
  aapFilter: FilterConfig;
  acronymeFilter: FilterConfig;
  denominationSocialeFilter: FilterConfig;
  processFR30Filter: FilterConfig;

  loadingMap = new Map();

  constructor(
    public rechercheTransverseService: RechercheTransverseService,
    public toastrService: ShowToastrService,
    private flechageBudgetaireService: FlechageBudgetaireService,
    public rechercheTransverseFiltersService: RechercheTransverseFiltersService
  ) {
    this.dispositifFilter = { label: 'Dispositif', source: 'dispositif', type: FilterType.MULTI_SELECT, dataList: [], selectedList: [] };
    this.aapFilter = {
      label: 'Appel à projet',
      source: 'aap',
      type: FilterType.MULTI_SELECT_AUTO_COMPLETE,
      dataList: [],
      selectedList: [],
    };
    this.acronymeFilter = {
      label: 'Acronyme projet',
      source: 'acronyme',
      type: FilterType.MULTI_SELECT_AUTO_COMPLETE,
      dataList: [],
      selectedList: [],
    };
    this.denominationSocialeFilter = {
      label: "Nom de l'entreprise",
      source: 'denomination_sociale',
      type: FilterType.MULTI_SELECT_AUTO_COMPLETE,
      dataList: [],
      selectedList: [],
    };
    this.processFR30Filter = {
      label: 'Statut process FR30',
      source: 'process_fr30',
      type: FilterType.MULTI_SELECT,
      dataList: this.rechercheTransverseFiltersService.getDataList(StatutProcessFR30),
      selectedList: [],
    };

    this.loadingMap.set(this.DISPOSITIF, true);
    this.loadingMap.set(this.AAP, false);
    this.loadingMap.set(this.ACRONYME, false);
    this.loadingMap.set(this.DENOMINATION_SOCIALE, false);
  }

  ngOnInit(): void {
    this.loadDispositifs();
  }

  public getFiltersValues(): FiltersCriteria {
    return this.rechercheTransverseFiltersService.getFiltersValues([
      this.dispositifFilter,
      this.aapFilter,
      this.acronymeFilter,
      this.denominationSocialeFilter,
      this.processFR30Filter,
    ]);
  }

  resetFilters(): void {
    this.multiselectComponents.forEach(multiselectComponent => {
      multiselectComponent?.reset();
    });
    [this.dispositifFilter, this.aapFilter, this.acronymeFilter, this.denominationSocialeFilter, this.processFR30Filter].forEach(filter => {
      filter.selectedList = [];
    });
  }

  loadDispositifs(): void {
    this.flechageBudgetaireService
      .getDispositifs()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: dispositifs => {
          this.dispositifFilter.dataList = dispositifs.map(dispositif => ({ id: dispositif.id, value: dispositif.code }));
          this.loadingMap.set(this.DISPOSITIF, false);
        },
        error: (err: HttpErrorResponse) => {
          this.toastrService.checkCodeError(err?.error);
        },
      });
  }
}
