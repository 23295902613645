import { FilterDataInterface, MultiSelectCriteria, RangeCriteria, RangeDatesCriteria } from '@shared-ui';
import { IntervenantRoles } from '../../../shared/enums/enum.intervenantRoles';
import { TableCellType, TableColumnConfiguration } from '../../../shared/interfaces/table-column-configuration.interface';

export enum FilterType {
  RANGE = 'RANGE',
  RANGE_DATE = 'RANGE_DATE',
  MULTI_SELECT = 'MULTI_SELECT',
  MULTI_SELECT_AUTO_COMPLETE = 'MULTI_SELECT_AUTO_COMPLETE',
  SIMPLE_INPUT = 'SIMPLE_INPUT',
  OUI_NON_RADIO_BUTTON = 'OUI_NON_RADIO_BUTTON',
}

export interface FilterConfig {
  label: string;
  source: string;
  type: FilterType;
  dataList?: FilterDataInterface[];
  selectedList?: FilterDataInterface[];
  selectedValue?: string;
  min?: number;
  max?: number;
  dateDebut?: Date;
  dateFin?: Date;
  simpleInputValue?: string;
  restrictedRoles?: IntervenantRoles[];
}

export interface FiltersCriteria {
  rangeFilters: RangeCriteria;
  selectFilters: MultiSelectCriteria;
  rangeDatesCriterias: RangeDatesCriteria;
  inputValue?: string | undefined;
  objetProjet?: string | undefined;
}

export interface RechercheTransverseFiltersConfig {
  dateFilters: FilterConfig[];
  interneFilters: FilterConfig[];
  partenaireFilters: FilterConfig[];
  partenaireContractualisationFilters: FilterConfig[];
  projetFilters: FilterConfig[];
  [key: string]: FilterConfig[];
}

export const RECHERCHE_TRANSVERSE_FILTERS_CONFIG: RechercheTransverseFiltersConfig = {
  dateFilters: [
    { label: 'Date de relève', source: 'date_de_releve', type: FilterType.RANGE_DATE },
    { label: 'Copil: date de passage', source: 'copil_date_de_passage_en_comite', type: FilterType.RANGE_DATE },
  ],
  projetFilters: [
    { label: 'Nombre de partenaire', source: 'nb_de_partenaires', type: FilterType.RANGE, min: undefined, max: undefined },
    { label: 'Thématique', source: 'thematique', type: FilterType.MULTI_SELECT_AUTO_COMPLETE, dataList: [], selectedList: [] },
    {
      label: 'Pôle de compétitivité',
      source: 'pole_de_competitivite_labellisateur',
      type: FilterType.MULTI_SELECT_AUTO_COMPLETE,
      dataList: [],
      selectedList: [],
    },
    { label: 'Objet du projet', source: 'objetProjet', type: FilterType.SIMPLE_INPUT },
    { label: 'Date de verrouillage', source: 'date_de_verrouillage', type: FilterType.RANGE_DATE },
    { label: 'PIA vert', source: 'pia_vert', type: FilterType.MULTI_SELECT, dataList: [], selectedList: [] },
    { label: 'PIA numérique', source: 'pia_numerique', type: FilterType.MULTI_SELECT, dataList: [], selectedList: [] },
  ],
  interneFilters: [
    {
      label: 'Direction Régionale Bpifrance',
      source: 'direction_regionale_bpifrance',
      type: FilterType.MULTI_SELECT_AUTO_COMPLETE,
      dataList: [],
      selectedList: [],
    },
    {
      label: 'Personne affectée Eligibilité-LCBFT-OAD',
      source: 'personne_affectee_eligibilite_lcbft_oad',
      type: FilterType.MULTI_SELECT_AUTO_COMPLETE,
      dataList: [],
      selectedList: [],
      restrictedRoles: [],
    },
    {
      label: "Personne affectée à l'instruction",
      source: 'personne_affectee_a_l_instruction',
      type: FilterType.MULTI_SELECT_AUTO_COMPLETE,
      dataList: [],
      selectedList: [],
      restrictedRoles: [],
    },
    {
      label: 'COEX: Chargé de développement',
      source: 'coex_charge_de_developpement',
      type: FilterType.MULTI_SELECT_AUTO_COMPLETE,
      dataList: [],
      selectedList: [],
      restrictedRoles: [],
    },
    {
      label: 'Expertise juridique et/ou PI interne',
      source: 'expertise_juridique_et_ou_pi_interne',
      type: FilterType.MULTI_SELECT_AUTO_COMPLETE,
      dataList: [],
      selectedList: [],
      restrictedRoles: [],
    },
    {
      label: 'Référent contractuel',
      source: 'referent_contractuel',
      type: FilterType.MULTI_SELECT_AUTO_COMPLETE,
      dataList: [],
      selectedList: [],
      restrictedRoles: [],
    },
    {
      label: 'Responsable de suivi',
      source: 'responsable_de_suivi',
      type: FilterType.MULTI_SELECT_AUTO_COMPLETE,
      dataList: [],
      selectedList: [],
      restrictedRoles: [],
    },
    {
      label: 'Délai de caducite de la DPM (en mois)',
      source: 'delai_de_caducite_de_la_dpm_en_mois',
      type: FilterType.MULTI_SELECT_AUTO_COMPLETE,
      restrictedRoles: [IntervenantRoles.COORDINATEUR_CPMO, IntervenantRoles.SGPI_DIRECTION_EVALUATION],
    },
    {
      label: 'Numéro CP (Client Partenaire source BCP)',
      source: 'numero_cp_client_partenaire_source_bcp',
      type: FilterType.MULTI_SELECT_AUTO_COMPLETE,
      dataList: [],
      selectedList: [],
      restrictedRoles: [],
    },
    {
      label: 'Instruction affectée autre opérateur',
      source: 'instruction_affectee_autre_operateur',
      type: FilterType.MULTI_SELECT,
      dataList: [],
      selectedList: [],
      restrictedRoles: [IntervenantRoles.COORDINATEUR_CPMO, IntervenantRoles.SGPI_DIRECTION_EVALUATION],
    },
  ],
  partenaireFilters: [
    {
      label: 'Assiette présentée',
      source: 'assiete_presentee',
      type: FilterType.RANGE,
      min: undefined,
      max: undefined,
      restrictedRoles: [],
    },
    {
      label: 'Assiette totale retenue',
      source: 'assiette_totale_retenue',
      type: FilterType.RANGE,
      min: undefined,
      max: undefined,
      restrictedRoles: [IntervenantRoles.COORDINATEUR_CPMO, IntervenantRoles.SGPI_DIRECTION_EVALUATION],
    },
    {
      label: "Montant total de l'aide retenue HT",
      source: 'montant_total_de_l_aide_retenue_ht',
      type: FilterType.RANGE,
      min: undefined,
      max: undefined,
      restrictedRoles: [IntervenantRoles.COORDINATEUR_CPMO, IntervenantRoles.SGPI_DIRECTION_EVALUATION],
    },
    {
      label: 'Code postal de réalisation / partenaire',
      source: 'code_postal_de_realisation_partenaire',
      type: FilterType.MULTI_SELECT_AUTO_COMPLETE,
      dataList: [],
      selectedList: [],
    },
    { label: 'SIRET', source: 'siret', type: FilterType.MULTI_SELECT_AUTO_COMPLETE, dataList: [], selectedList: [] },
    { label: 'SIREN', source: 'siren', type: FilterType.MULTI_SELECT_AUTO_COMPLETE, dataList: [], selectedList: [] },
    {
      label: 'N° projet PXL-PIC',
      source: 'n_projet_pic_pxl',
      type: FilterType.MULTI_SELECT_AUTO_COMPLETE,
      dataList: [],
      selectedList: [],
      restrictedRoles: [],
    },
    {
      label: 'Nom du groupe',
      source: 'nom_du_groupe',
      type: FilterType.MULTI_SELECT_AUTO_COMPLETE,
      dataList: [],
      selectedList: [],
      restrictedRoles: [IntervenantRoles.COORDINATEUR_CPMO, IntervenantRoles.SGPI_DIRECTION_EVALUATION],
    },
  ],
  partenaireContractualisationFilters: [
    {
      label: 'Edition du contrat',
      source: 'edition_du_contrat',
      type: FilterType.MULTI_SELECT,
      dataList: [],
      selectedList: [],
      restrictedRoles: [],
    },
    {
      label: 'Livrables EC1 (Clause 1)',
      source: 'livrables_ec1',
      type: FilterType.MULTI_SELECT_AUTO_COMPLETE,
      dataList: [],
      selectedList: [],
      restrictedRoles: [],
    },
    {
      label: 'Conditions EC1 (Clause 1)',
      source: 'conditions_ec1',
      type: FilterType.MULTI_SELECT_AUTO_COMPLETE,
      dataList: [],
      selectedList: [],
      restrictedRoles: [],
    },
    {
      label: 'Livrables EC2 (Clause 2)',
      source: 'livrables_ec2',
      type: FilterType.MULTI_SELECT_AUTO_COMPLETE,
      dataList: [],
      selectedList: [],
      restrictedRoles: [],
    },
    {
      label: 'Conditions EC2 (Clause 2)',
      source: 'conditions_ec2',
      type: FilterType.MULTI_SELECT_AUTO_COMPLETE,
      dataList: [],
      selectedList: [],
      restrictedRoles: [],
    },
    {
      label: 'Livrables EC3 (Clause 3)',
      source: 'livrables_ec3',
      type: FilterType.MULTI_SELECT_AUTO_COMPLETE,
      dataList: [],
      selectedList: [],
      restrictedRoles: [],
    },
    {
      label: 'Conditions EC3 (Clause 3)',
      source: 'conditions_ec3',
      type: FilterType.MULTI_SELECT_AUTO_COMPLETE,
      dataList: [],
      selectedList: [],
      restrictedRoles: [],
    },
    {
      label: "Régime d'aide 1",
      source: 'regime_aide_1',
      type: FilterType.MULTI_SELECT_AUTO_COMPLETE,
      dataList: [],
      selectedList: [],
      restrictedRoles: [],
    },
    {
      label: "Régime d'aide 2",
      source: 'regime_aide_2',
      type: FilterType.MULTI_SELECT_AUTO_COMPLETE,
      dataList: [],
      selectedList: [],
      restrictedRoles: [],
    },
    {
      label: "Régime d'aide 3",
      source: 'regime_aide_3',
      type: FilterType.MULTI_SELECT_AUTO_COMPLETE,
      dataList: [],
      selectedList: [],
      restrictedRoles: [],
    },
    {
      label: "Régime d'aide 4",
      source: 'regime_aide_4',
      type: FilterType.MULTI_SELECT_AUTO_COMPLETE,
      dataList: [],
      selectedList: [],
      restrictedRoles: [],
    },
    {
      label: 'Solidarité',
      source: 'solidarite',
      type: FilterType.OUI_NON_RADIO_BUTTON,
      selectedValue: undefined,
      restrictedRoles: [],
    },
  ],
};

export const RECHERCHE_TRANSVERSE_TABLE_CONFIG = [
  { id: 'produitInnovant', label: 'Produit Innovant', nom: 'produitInnovant', ordre: 0, visible: true, disabled: true },
  { id: 'dispositif', label: 'Dispositif', nom: 'dispositif', ordre: 1, visible: true, disabled: true },
  { id: 'aap', label: 'Code AAP', ordre: 2, nom: 'aap', visible: true, disabled: true },
  { id: 'acronyme', label: 'Acronyme projet', nom: 'acronyme', ordre: 3, visible: true, disabled: true },
  { id: 'denominationSociale', label: 'Dénomination Sociale', nom: 'denominationSociale', ordre: 4, visible: true, disabled: true },
  { id: 'statutAvancement', label: 'Statut', nom: 'statutAvancement', ordre: 5, visible: true, disabled: true },
  {
    id: 'montantTotalDeLAideRetenueHt',
    label: 'Montant engagé (€)',
    nom: 'montantTotalDeLAideRetenueHt',
    ordre: 6,
    visible: true,
    disabled: true,
    type: TableCellType.NUMBER,
    restrictedRoles: [IntervenantRoles.COORDINATEUR_CPMO, IntervenantRoles.SGPI_DIRECTION_EVALUATION],
  },
  {
    id: 'dateDeSignatureClient',
    label: 'Date de signature client',
    nom: 'dateDeSignatureClient',
    ordre: 7,
    visible: true,
    disabled: true,
    isDate: true,
    type: TableCellType.DATE,
  },
  {
    id: 'dateDeReleve',
    label: 'Date de relève',
    nom: 'dateDeReleve',
    ordre: 8,
    visible: false,
    disabled: false,
    isDate: true,
    type: TableCellType.DATE,
  },
  {
    id: 'nomDuChefDeFile',
    label: 'Nom du Chef de file',
    nom: 'nomDuChefDeFile',
    ordre: 9,
    visible: true,
    disabled: false,
    restrictedRoles: [IntervenantRoles.SGPI_DIRECTION_FINANCIERE],
  },
  {
    id: 'assietePresentee',
    label: 'Assiette présentée',
    nom: 'assietePresentee',
    ordre: 10,
    visible: false,
    disabled: false,
    type: TableCellType.NUMBER,
  },
  {
    id: 'nbDePartenaires',
    label: 'Nb de partenaires',
    nom: 'nbDePartenaires',
    ordre: 11,
    visible: false,
    disabled: false,
    type: TableCellType.NUMBER,
  },
  { id: 'thematique', label: 'Thématique', nom: 'thematique', ordre: 12, visible: false, disabled: false },
  {
    id: 'typeEntreprise',
    label: "Type d'entreprise",
    nom: 'typeEntreprise',
    ordre: 13,
    visible: true,
    disabled: false,
    restrictedRoles: [IntervenantRoles.SGPI_DIRECTION_FINANCIERE],
  },
  {
    id: 'codePostalDeRealisationPartenaire',
    label: 'Code postal de réalisation / partenaire',
    nom: 'codePostalDeRealisationPartenaire',
    ordre: 14,
    visible: false,
    disabled: false,
  },
  {
    id: 'poleDeCompetitiviteLabellisateur',
    label: 'Pôle de compétitivité labellisateur',
    nom: 'poleDeCompetitiviteLabellisateur',
    ordre: 15,
    visible: false,
    disabled: false,
  },
  { id: 'description', label: 'Objet du projet (description courte)', nom: 'description', ordre: 16, visible: false, disabled: false },
  {
    id: 'directionRegionaleBpifrance',
    label: 'Direction Régionale Bpifrance',
    nom: 'directionRegionaleBpifrance',
    ordre: 17,
    visible: false,
    disabled: false,
  },
  { id: 'siret', label: 'SIRET', nom: 'siret', ordre: 18, visible: false, disabled: false, type: TableCellType.SIRET },
  { id: 'siren', label: 'SIREN', nom: 'siren', ordre: 19, visible: false, disabled: false, type: TableCellType.SIREN },
  {
    id: 'nomResponsableProjet',
    label: 'Partenaire Nom du responsable projet',
    nom: 'nomResponsableProjet',
    ordre: 20,
    visible: false,
    disabled: false,
    restrictedRoles: [],
  },
  {
    id: 'prenomResponsableProjet',
    label: 'Partenaire Prénom du responsable projet',
    nom: 'prenomResponsableProjet',
    ordre: 21,
    visible: false,
    disabled: false,
    restrictedRoles: [],
  },
  {
    id: 'personneAffecteeEligibiliteLcbftOad',
    label: 'Personne affectée Eligibilité-LCBFT-OAD',
    nom: 'personneAffecteeEligibiliteLcbftOad',
    ordre: 22,
    visible: false,
    disabled: false,
    restrictedRoles: [],
  },
  {
    id: 'personneAffecteeALInstruction',
    label: "Personne affectée à l'instruction",
    nom: 'personneAffecteeALInstruction',
    ordre: 23,
    visible: false,
    disabled: false,
    restrictedRoles: [],
  },
  {
    id: 'propositionDecisionOperateur',
    label: 'Proposition / Décision Opérateur',
    nom: 'propositionDecisionOperateur',
    ordre: 24,
    visible: false,
    disabled: false,
    restrictedRoles: [],
  },
  {
    id: 'coexChargeDeDeveloppement',
    label: 'COEX : Chargé de développement',
    nom: 'coexChargeDeDeveloppement',
    ordre: 25,
    visible: false,
    disabled: false,
    restrictedRoles: [],
  },
  {
    id: 'coexDecisionComite',
    label: 'COEX : Décision Comité',
    nom: 'coexDecisionComite',
    ordre: 26,
    visible: false,
    disabled: false,
    restrictedRoles: [IntervenantRoles.COORDINATEUR_CPMO, IntervenantRoles.SGPI_DIRECTION_EVALUATION],
  },
  {
    id: 'reorientationYcAutreOperateurFlechage',
    label: 'Réorientation (yc autre opérateur) / Fléchage',
    nom: 'reorientationYcAutreOperateurFlechage',
    ordre: 27,
    visible: false,
    disabled: false,
    restrictedRoles: [IntervenantRoles.COORDINATEUR_CPMO, IntervenantRoles.SGPI_DIRECTION_EVALUATION],
  },
  {
    id: 'auditionDecision',
    label: 'Audition : Décision',
    nom: 'auditionDecision',
    ordre: 28,
    visible: false,
    disabled: false,
    restrictedRoles: [IntervenantRoles.COORDINATEUR_CPMO, IntervenantRoles.SGPI_DIRECTION_EVALUATION],
  },
  {
    id: 'expertiseJuridiqueEtOuPiInterne',
    label: 'Expertise juridique et/ou PI interne',
    nom: 'expertiseJuridiqueEtOuPiInterne',
    ordre: 29,
    visible: false,
    disabled: false,
    restrictedRoles: [],
  },
  {
    id: 'assietteTotaleRetenue',
    label: 'Assiette totale retenue',
    nom: 'assietteTotaleRetenue',
    ordre: 30,
    visible: false,
    disabled: false,
    type: TableCellType.NUMBER,
    restrictedRoles: [IntervenantRoles.COORDINATEUR_CPMO, IntervenantRoles.SGPI_DIRECTION_EVALUATION],
  },
  {
    id: 'resultatDeLInstruction',
    label: "Résultat de l'instruction",
    nom: 'resultatDeLInstruction',
    ordre: 31,
    visible: false,
    disabled: false,
    restrictedRoles: [],
  },
  {
    id: 'copilDecisionComite',
    label: 'COPIL : Décision Comité',
    nom: 'copilDecisionComite',
    ordre: 32,
    visible: false,
    disabled: false,
    restrictedRoles: [IntervenantRoles.COORDINATEUR_CPMO, IntervenantRoles.SGPI_DIRECTION_EVALUATION],
  },
  {
    id: 'copilDateDeSignatureDpm',
    label: 'COPIL : Date de signature DPM',
    nom: 'copilDateDeSignatureDpm',
    ordre: 33,
    visible: false,
    disabled: false,
    isDate: true,
    type: TableCellType.DATE,
  },
  {
    id: 'referentContractuel',
    label: 'Référent contractuel',
    nom: 'referentContractuel',
    ordre: 34,
    visible: false,
    disabled: false,
    restrictedRoles: [],
  },
  {
    id: 'responsableDeSuivi',
    label: 'Responsable de suivi',
    nom: 'responsableDeSuivi',
    ordre: 35,
    visible: false,
    disabled: false,
    restrictedRoles: [],
  },
  {
    id: 'secteurDActivite',
    label: "Secteur d'activité BAD",
    nom: 'secteurDActivite',
    ordre: 36,
    visible: false,
    disabled: false,
    restrictedRoles: [IntervenantRoles.SGPI_DIRECTION_FINANCIERE],
  },
  {
    id: 'sousSecteurDActivite',
    label: "Sous secteur d'activité BAD",
    nom: 'sousSecteurDActivite',
    ordre: 37,
    visible: false,
    disabled: false,
    restrictedRoles: [IntervenantRoles.SGPI_DIRECTION_FINANCIERE],
  },
  {
    id: 'source',
    label: 'Source de la donnée',
    nom: 'source',
    ordre: 38,
    visible: false,
    disabled: false,
    restrictedRoles: [],
  },
  {
    id: 'produit',
    label: 'Produit',
    nom: 'produit',
    ordre: 39,
    visible: true,
    disabled: true,
  },
  {
    id: 'editionDuContrat',
    label: 'Edition du contrat',
    nom: 'editionDuContrat',
    ordre: 40,
    visible: false,
    disabled: false,
    restrictedRoles: [],
  },
  {
    id: 'livrablesEc1',
    label: 'Livrables EC1 (Clause 1)',
    nom: 'livrablesEc1',
    ordre: 41,
    visible: false,
    disabled: false,
    restrictedRoles: [],
  },
  {
    id: 'conditionsEc1',
    label: 'Conditions EC1 (Clause 1)',
    nom: 'conditionsEc1',
    ordre: 42,
    visible: false,
    disabled: false,
    restrictedRoles: [],
  },
  {
    id: 'livrablesEc2',
    label: 'Livrables EC2 (Clause 2)',
    nom: 'livrablesEc2',
    ordre: 43,
    visible: false,
    disabled: false,
    restrictedRoles: [],
  },
  {
    id: 'conditionsEc2',
    label: 'Conditions EC2 (Clause 2)',
    nom: 'conditionsEc2',
    ordre: 44,
    visible: false,
    disabled: false,
    restrictedRoles: [],
  },
  {
    id: 'livrablesEc3',
    label: 'Livrables EC3 (Clause 3)',
    nom: 'livrablesEc3',
    ordre: 45,
    visible: false,
    disabled: false,
    restrictedRoles: [],
  },
  {
    id: 'conditionsEc3',
    label: 'Conditions EC3 (Clause 3)',
    nom: 'conditionsEc3',
    ordre: 46,
    visible: false,
    disabled: false,
    restrictedRoles: [],
  },
  {
    id: 'regimeAide1',
    label: "Régime d'aide 1",
    nom: 'regimeAide1',
    ordre: 47,
    visible: false,
    disabled: false,
    restrictedRoles: [],
  },
  {
    id: 'regimeAide2',
    label: "Régime d'aide 2",
    nom: 'regimeAide2',
    ordre: 48,
    visible: false,
    disabled: false,
    restrictedRoles: [],
  },
  {
    id: 'regimeAide3',
    label: "Régime d'aide 3",
    nom: 'regimeAide3',
    ordre: 49,
    visible: false,
    disabled: false,
    restrictedRoles: [],
  },
  {
    id: 'regimeAide4',
    label: "Régime d'aide 4",
    nom: 'regimeAide4',
    ordre: 50,
    visible: false,
    disabled: false,
    restrictedRoles: [],
  },
  {
    id: 'solidarite',
    label: 'Solidarité',
    nom: 'solidarite',
    ordre: 51,
    visible: false,
    disabled: false,
    restrictedRoles: [],
  },
  {
    id: 'instructionAffecteeAutreOperateur',
    label: 'Instruction affectée autre opérateur',
    nom: 'instructionAffecteeAutreOperateur',
    ordre: 52,
    visible: false,
    disabled: false,
    restrictedRoles: [IntervenantRoles.COORDINATEUR_CPMO, IntervenantRoles.SGPI_DIRECTION_EVALUATION],
  },
  {
    id: 'nomDuGroupe',
    label: 'Nom du groupe',
    nom: 'nomDuGroupe',
    ordre: 53,
    visible: false,
    disabled: false,
    restrictedRoles: [IntervenantRoles.COORDINATEUR_CPMO, IntervenantRoles.SGPI_DIRECTION_EVALUATION],
  },
  {
    id: 'delaiDeCaduciteDeLaDpmEnMois',
    label: 'Délai de caducite de la DPM (en mois)',
    nom: 'delaiDeCaduciteDeLaDpmEnMois',
    ordre: 54,
    visible: false,
    disabled: false,
    restrictedRoles: [IntervenantRoles.COORDINATEUR_CPMO, IntervenantRoles.SGPI_DIRECTION_EVALUATION],
  },
  {
    id: 'dispositifOperateurCible',
    label: 'Dispositif / Opérateur cible',
    nom: 'dispositifOperateurCible',
    ordre: 55,
    visible: false,
    disabled: false,
    restrictedRoles: [IntervenantRoles.COORDINATEUR_CPMO, IntervenantRoles.SGPI_DIRECTION_EVALUATION],
  },
  {
    id: 'aapCible',
    label: 'AAP cible',
    nom: 'aapCible',
    ordre: 56,
    visible: false,
    disabled: false,
    restrictedRoles: [IntervenantRoles.COORDINATEUR_CPMO, IntervenantRoles.SGPI_DIRECTION_EVALUATION],
  },
  {
    id: 'auditionNoteFinale',
    label: 'Audition: Note finale',
    nom: 'auditionNoteFinale',
    ordre: 57,
    visible: false,
    disabled: false,
    restrictedRoles: [IntervenantRoles.COORDINATEUR_CPMO, IntervenantRoles.SGPI_DIRECTION_EVALUATION],
  },
  {
    id: 'montantDeLaSubvention',
    label: 'Montant de la subvention',
    nom: 'montantDeLaSubvention',
    ordre: 58,
    visible: false,
    disabled: false,
    restrictedRoles: [IntervenantRoles.COORDINATEUR_CPMO, IntervenantRoles.SGPI_DIRECTION_EVALUATION],
  },
  {
    id: 'montantDeLAvanceRemboursable',
    label: "Montant de l'avance remboursable",
    nom: 'montantDeLAvanceRemboursable',
    ordre: 59,
    visible: false,
    disabled: false,
    restrictedRoles: [IntervenantRoles.COORDINATEUR_CPMO, IntervenantRoles.SGPI_DIRECTION_EVALUATION],
  },
  {
    id: 'tauxAideGlobal',
    label: 'Taux aide global',
    nom: 'tauxAideGlobal',
    ordre: 60,
    visible: false,
    disabled: false,
    restrictedRoles: [IntervenantRoles.COORDINATEUR_CPMO, IntervenantRoles.SGPI_DIRECTION_EVALUATION],
  },
  {
    id: 'convention',
    label: 'Convention',
    nom: 'convention',
    ordre: 61,
    visible: false,
    disabled: false,
  },
  {
    id: 'dateDAbandonClient',
    label: "Date d'abandon client",
    nom: 'dateDAbandonClient',
    ordre: 62,
    visible: false,
    disabled: false,
    isDate: true,
    restrictedRoles: [IntervenantRoles.COORDINATEUR_CPMO, IntervenantRoles.SGPI_DIRECTION_EVALUATION],
  },
  {
    id: 'typeDeProjet',
    label: 'Type de projet',
    nom: 'typeDeProjet',
    ordre: 63,
    visible: false,
    disabled: false,
  },
] as TableColumnConfiguration[];
