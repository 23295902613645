<div
  class="card--info d-block shadow"
  [ngClass]="{
    'card--blue': type === EnumTypologieMessageAlerte.INFORMATION,
    'card--green': type === EnumTypologieMessageAlerte.RESOLUTION,
    'card--yellow': type === EnumTypologieMessageAlerte.MAINTENANCE,
  }"
>
  <div class="d-flex mt-2">
    <div class="d-flex w-100 justify-content-between">
      <div>
        <lib-pxl-icon class="icon" [icon]="getIcon(type)"></lib-pxl-icon>
        <span class="fw-bold">{{ titre }}</span>
      </div>
      <a class="clickable" (click)="close()">
        <lib-pxl-icon class="icon" [icon]="'ic_close'"></lib-pxl-icon>
      </a>
    </div>
  </div>
  <div [innerHtml]="message" class="message mt-2">
    <div *ngIf="content" class="content">{{ content }}</div>
  </div>
  <div class="message ms-3 pb-2">
    <span *ngIf="dateDebut"><span class="fw-bold">Début: </span>{{ dateDebut | date : 'dd/MM/yyyy' }}</span>
    <span *ngIf="heureDebut"> à {{ heureDebut }}</span>
    <span *ngIf="dateFin"> - <span class="fw-bold">Fin prévue:</span> {{ dateFin | date : 'dd/MM/yyyy' }}</span>
    <span *ngIf="heureFin"> à {{ heureFin }}</span>
  </div>
</div>
