import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

export const TECHNICAL_CODE = '1004';

@Injectable({
  providedIn: 'root',
})
export class ShowToastrService {
  public constructor(private toastr: ToastrService) {}

  /*
   * Cette méthode affiche le toastr en fonction du code d'erreur renvoyé pas le backend
   * */
  public checkCodeError(error?: { code: string; message?: string }, data?: string): void {
    if (error && (error as unknown as string) === 'INCOMPLETE_EVAL') {
      error = { code: 'INCOMPLETE_EVAL', message: error?.message };
    }
    if (!error || !error.code) {
      error = { code: '0500', message: error?.message };
    }

    let toast: { message: string; title?: string; timeout: number | null } | null = null;
    switch (error.code) {
      case 'INCOMPLETE_EVAL':
        toast = {
          message: 'merci de cliquer sur le lien dans le mail pour finaliser votre inscription et pouvoir accéder aux projets',
          title: "Vous avez reçu une invitation dans votre boîte mail pour vous permettre d'accéder à l'espace évaluateur,",
          timeout: null,
        };
        break;
      case 'error.accessDenied':
        toast = {
          message: 'Vos droits ne vous permettent pas cette action.',
          title: 'Action Impossible',
          timeout: 10000,
        };
        break;
      case '1103':
        toast = {
          message: 'Veuillez vérifier si des AAPs ouvertes y sont encore rattachées.',
          title: 'Le programme ne peut être supprimé.',
          timeout: 10000,
        };
        break;
      case '1104':
        toast = {
          message: 'car un enregistrement concomitant vous cache certaines données.',
          title: 'Veuillez rafraichir la page ',
          timeout: 10000,
        };
        break;
      case '1204':
        toast = {
          message: 'car un enregistrement concomitant vous cache certaines données.',
          title: 'Veuillez rafraichir la page ',
          timeout: 10000,
        };
        break;
      case '1301':
        toast = {
          message: 'Cette action ne peut donc pas être complétée.',
          title: "L'AAP n'est pas ouverte. ",
          timeout: 10000,
        };
        break;
      case 'document.present':
        toast = {
          message: 'Une lettre de labélisation est déjà chargée sur le pôle.' + data,
          title: 'Document déja chargé',
          timeout: 10000,
        };
        break;
      case '1302':
        toast = {
          message: 'car un enregistrement concomitant vous cache certaines données.',
          title: 'Veuillez rafraichir la page ',
          timeout: 10000,
        };
        break;
      case '1306':
        toast = {
          message: "Vous essayez d'activer votre compte avec une adresse Email autre que celle sur laquelle vous avez été invité.",
          title: 'Action Impossible',
          timeout: 10000,
        };
        break;
      case '1307':
        toast = {
          message: "Le lien d'invitation n'est plus actif, merci d'en demander un nouveau auprès de la personne qui vous a invité",
          title: 'Action Impossible',
          timeout: 10000,
        };
        break;
      case '1319':
        toast = {
          message: 'Vous avez sélectionné plus de 50 projets',
          title: '',
          timeout: 10000,
        };
        break;
      case '2101':
        toast = {
          message: 'Il existe une donnée avec le même nom (Type de programme comptable, Type de programme libre ou Nom du programme).',
          title: 'Merci de bien vouloir renommer cette donnée.',
          timeout: 10000,
        };
        break;
      case '2201':
        toast = {
          message:
            "Il existe une donnée avec le même nom (Nom de l'AAP (référence comptable), Nom de l'AAP à créer ou Nom de l'AAP pour les candidats).",
          title: 'Merci de bien vouloir renommer cette donnée.',
          timeout: 10000,
        };
        break;
      case 'poles.non.enregistrés':
      case 'secteurs.non.enregistrés':
        toast = {
          message: "Les modifications apportées n'ont pas été enregistrées",
          title: 'Perte de données',
          timeout: 10000,
        };
        break;
      case 'erreur.telechargement.document':
        toast = {
          message: "Merci de réessayer l'action, une erreur s'est produite.",
          title: '',
          timeout: 10000,
        };
        break;
      case '1331':
        toast = {
          message: `L'accès est déjà validé pour cet utilisateur`,
          title: undefined,
          timeout: 10000,
        };
        break;
      case '1332':
        toast = {
          message: `Il devra renouveller sa demande d'accès à son espace dans PICXEL`,
          title: `Le lien de validation d'accès au compte de cet utilisateur a expiré`,
          timeout: 10000,
        };
        break;
      case '1333':
        toast = {
          message: `cette action n'est pas autorisée`,
          title: undefined,
          timeout: 10000,
        };
        break;
      case '1334':
        toast = {
          message: 'Veuillez vous déconnecter et reconnecter vous au bon compte avant de recliquer sur le lien de réactivation.',
          title: 'Vous avez tenté de réactiver un compte associé à une autre adresse mail.',
          timeout: 10000,
        };
        break;
      case '1370':
        toast = {
          message: `L'acronyme renseigné existe déjà`,
          title: undefined,
          timeout: 10000,
        };
        break;
      case '1371':
        toast = {
          message: `l'acronyme doit être composé de chiffres et/ou de lettres uniquement`,
          title: undefined,
          timeout: 10000,
        };
        break;
      case '1379':
        toast = {
          message: `Un ou plusieurs projets sont déjà associés à un comité actif du même type.`,
          title: undefined,
          timeout: 10000,
        };
        break;
      case '2528':
        toast = {
          message: `Une des sections n'est pas valide.`,
          title: undefined,
          timeout: 10000,
        };
        break;
      case '2530':
        toast = {
          message: `Un contact avec cet email existe déjà pour ce projet.`,
          title: undefined,
          timeout: 10000,
        };
        break;
    }

    if (toast) {
      this.toastr.error(toast.message, toast.title, toast.timeout ? { timeOut: toast.timeout } : { disableTimeOut: true });
    } else if (error.message && error.code !== TECHNICAL_CODE) {
      this.toastr.error(error.message, undefined, { timeOut: 10000 });
    } else {
      this.toastr.error(
        '<b>Si le problème persiste, veuillez contacter </b>' +
          '<a href="mailto:picxel@bpifrance.fr?Subject=PICXEL – Erreur Picxel-Front-ko"><b>picxel@bpifrance.fr</b></a>' +
          '<b> avec le code erreur "Picxel-Front-ko"</b>',
        'Une erreur technique est survenue, merci de recharger la page.',
        {
          timeOut: 10000,
          enableHtml: true,
          messageClass: 'toastr-a-msg',
        }
      );
    }
  }

  success(message?: string, title?: string, override?: any): void {
    this.toastr.success(message, title, override);
  }

  warning(message?: string, title?: string, override?: any): void {
    this.toastr.warning(message, title, override);
  }

  error(message?: string, title?: string, override?: any): void {
    this.toastr.error(message, title, override);
  }
  errorHtml(message?: string, title?: string, override?: any): void {
    const options = { ...override, enableHtml: true };
    this.toastr.error(message, title, options);
  }

  /*
   * Cette méthode informe l'utilisateur du statut non ouvert de l'Appel à projet
   * */
  public aapNotOuvert(): void {
    this.toastr.info(undefined, "L'AAP est clôturée. Vous ne pouvez plus ni modifier ni envoyer votre projet.", {
      timeOut: 0,
    });
  }

  /*
   * Cette méthode informe l'utilisateur du statut non ouvert de l'Appel à projet
   * */
  public clearToastr(id?: number): void {
    this.toastr.clear(id);
  }
}
