import { Component } from '@angular/core';
import { AlerteControlService } from '@shared-ui';

@Component({
  selector: 'lib-alerte-toggle',
  templateUrl: './alerte-toggle.component.html',
  styleUrl: './alerte-toggle.component.scss',
})
export class AlerteToggleComponent {
  constructor(public alertControlService: AlerteControlService) {}

  silence() {
    this.alertControlService.setSilenced(true);
  }

  show() {
    this.alertControlService.setSilenced(false);
  }
}
