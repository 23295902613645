<h2 id="modal-title" class="modal__title" *ngIf="modalData.title">{{ modalData.title }}</h2>

<form id="input-typeDoc" class="modal__content" [formGroup]="emailForm">
  <div
    class="ds-input-group"
    [class.ds-input-group--error]="(!emailForm.controls['email'].valid || alreadyExist) && emailForm.controls['email'].touched"
  >
    <label for="email" class="ds-input-group__label">Adresse mail du contact</label>
    <span class="ds-input-group__input">
      <input
        id="email"
        name="email"
        type="text"
        maxlength="80"
        pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
        placeholder="Ex: example@mail.fr"
        formControlName="email"
        class="input_email ds-input-group__input"
      />
    </span>
    <!-- Icône et message d'erreur -->
    <ng-container id="error-email" *ngIf="!emailForm.controls['email'].valid && emailForm.controls['email'].touched">
      <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un mail valide</div>
    </ng-container>
  </div>
  <br />
  <br />
  <div class="rgpd">
    <lib-mention-rgpd></lib-mention-rgpd>
  </div>
</form>

<div id="modal-btn" class="modal__actions">
  <button *ngIf="modalData.textReturnButton" class="ds-btn ds-btn--secondary" mat-dialog-close>
    {{ modalData.textReturnButton }}
  </button>
  <button class="ds-btn ds-btn--primary" *ngIf="modalData.isAuthorized" (click)="sendInvitation()" cdkFocusInitial>
    {{ modalData.textGoButton }}
  </button>
</div>
