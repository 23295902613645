import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MessageAlerte } from '../models/message-alerte.model';
import { Environment } from '../models/environment.model';
import { EnumPositionnement } from '../enums/enum.positionnement';

@Injectable({
  providedIn: 'root',
})
export class MessageAlerteHttpService {
  constructor(@Inject('environment') private environment: Environment, public httpClient: HttpClient) {}

  saveAlerte(alerte: MessageAlerte): Observable<HttpResponse<MessageAlerte>> {
    return this.saveCreateAlerte(alerte);
  }

  updateAlerte(alerte: MessageAlerte, idAlerte: string): Observable<HttpResponse<MessageAlerte>> {
    alerte.id = idAlerte;
    return this.httpClient.put<MessageAlerte>(`${this.environment.apiAdminUrl}messages-alerte/${idAlerte}`, JSON.stringify(alerte), {
      observe: 'response',
    });
  }

  getAlertes(): Observable<HttpResponse<MessageAlerte[]>> {
    return this.httpClient.get<MessageAlerte[]>(`${this.environment.apiAdminUrl}messages-alerte`, {
      observe: 'response',
    });
  }

  getAlerteById(id: string): Observable<MessageAlerte> {
    return this.httpClient.get<MessageAlerte>(`${this.environment.apiAdminUrl}messages-alerte/` + id);
  }

  deleteAlerte(id: string): Observable<MessageAlerte> {
    return this.httpClient.delete<MessageAlerte>(`${this.environment.apiAdminUrl}messages-alerte/` + id);
  }

  public saveCreateAlerte(alerte: MessageAlerte): Observable<HttpResponse<MessageAlerte>> {
    return this.httpClient.post<MessageAlerte>(`${this.environment.apiAdminUrl}messages-alerte`, JSON.stringify(alerte), {
      observe: 'response',
    });
  }

  public getAlertesPublieesForEvaluateur(
    positionnement: EnumPositionnement,
    aapId?: string,
    projetId?: string
  ): Observable<MessageAlerte[]> {
    let params = `publie&positionnement=${positionnement}`;
    if (aapId) params += `&aapId=${aapId}`;
    if (projetId) params += `&projetId=${projetId}`;

    return this.httpClient.get<MessageAlerte[]>(`${this.environment.apiUrl}messages-alerte/evaluateur?${params}`);
  }

  public getAlertesPublieesForCandidat(positionnement: EnumPositionnement): Observable<MessageAlerte[]> {
    const params = `publie&positionnement=${positionnement}`;
    return this.httpClient.get<MessageAlerte[]>(`${this.environment.apiUrl}messages-alerte/candidat?${params}`);
  }

  public getAlertesPublieesForInterne(positionnement: EnumPositionnement): Observable<MessageAlerte[]> {
    const params = `publie&positionnement=${positionnement}`;
    return this.httpClient.get<MessageAlerte[]>(`${this.environment.apiAdminUrl}messages-alerte/interne?${params}`);
  }

  public getAlertesPublieesForPublic(positionnement: EnumPositionnement): Observable<MessageAlerte[]> {
    const params = `publie&positionnement=${positionnement}`;
    return this.httpClient.get<MessageAlerte[]>(`${this.environment.apiUrl}messages-alerte/public?${params}`);
  }
}
