import { Component } from '@angular/core';
import { URL_RGPD } from '@shared-ui';

@Component({
  selector: 'lib-mention-rgpd',
  templateUrl: './mention-rgpd.component.html',
  styleUrls: ['./mention-rgpd.component.scss'],
})
export class MentionRgpdComponent {
  navigateToRGPD(): void {
    window.open(URL_RGPD);
  }
}
