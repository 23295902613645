import { DestroyRef, inject, Injectable } from '@angular/core';
import {
  DateTimeUtils,
  FilterDataInterface,
  FiltersCriteria,
  FilterType,
  MultiSelectCriteria,
  RangeCriteria,
  RangeDate,
  RangeDatesCriteria,
  RechercheTransverseService,
  ShowToastrService,
} from '@shared-ui';
import { FilterConfig } from '../../components/recherche-transverse/recherche-transverse/recherche-transverse.config';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class RechercheTransverseFiltersService {
  private destroyRef = inject(DestroyRef);

  readonly CODE_POSTAL = 'code_postal_de_realisation_partenaire';

  constructor(private rechercheTransverseService: RechercheTransverseService, private toastrService: ShowToastrService) {}

  onSelectionChange(event: FilterDataInterface[], filtre: FilterConfig): void {
    filtre.selectedList = event;
  }

  autocomplete(event: string, filtre: FilterConfig, loadingMap?: Map<string, boolean>): void {
    loadingMap?.set(filtre.source, true);
    const searchObject = {
      fieldName: filtre.source,
      query: this.removeElasticsearchSpecialCharacters(event),
      isPrefix: filtre.source === this.CODE_POSTAL,
    };

    this.rechercheTransverseService
      .getAutocomplete(searchObject)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: resultAutocomplete => {
          filtre.dataList = this.getDataList(resultAutocomplete.body as string[]);
          loadingMap?.set(filtre.source, false);
        },
        error: (err: HttpErrorResponse) => {
          this.toastrService.checkCodeError(err?.error);
          loadingMap?.set(filtre.source, false);
        },
      });
  }

  getDataList(data: string[]): FilterDataInterface[] {
    return data.map(value => ({ id: value, value: value }));
  }

  removeElasticsearchSpecialCharacters(str: string) {
    const specialCharacters = [
      '+',
      '-',
      '=',
      '&&',
      '.',
      '||',
      '>',
      '<',
      '!',
      '(',
      ')',
      '{',
      '}',
      '[',
      ']',
      '^',
      '"',
      '~',
      '*',
      '?',
      ':',
      '\\',
      '/',
    ];
    return str.replace(new RegExp(`[${specialCharacters.join('\\')}]`, 'g'), ' ');
  }

  getRangeCriteria(min: number | undefined, max: number | undefined): { start: number; end: number } | null {
    return min && max && min >= 0 && max >= 0 ? { start: min, end: max } : null;
  }

  getFieldCriteria(selectedList: FilterDataInterface[]): string[] {
    return selectedList.length > 0 ? selectedList.map(item => item.value) : [];
  }

  getFiltersValues(filters: FilterConfig[]): FiltersCriteria {
    const rangeCriteriaMap: RangeCriteria = {};
    const fieldCriteriaMap: MultiSelectCriteria = {};
    const rangeDatesCriteriaMap: RangeDatesCriteria = {};
    let inputValue: string | undefined = undefined;

    filters.forEach(filter => {
      switch (filter.type) {
        case FilterType.RANGE:
          rangeCriteriaMap[filter.source] = this.getRangeCriteria(filter.min, filter.max);
          break;
        case FilterType.OUI_NON_RADIO_BUTTON:
          fieldCriteriaMap[filter.source] = filter.selectedValue ? [filter.selectedValue] : [];
          break;
        case FilterType.MULTI_SELECT_AUTO_COMPLETE:
        case FilterType.MULTI_SELECT:
          fieldCriteriaMap[filter.source] = this.getFieldCriteria(filter.selectedList!);
          break;
        case FilterType.RANGE_DATE:
          rangeDatesCriteriaMap[filter.source] =
            filter.dateDebut && filter.dateFin
              ? {
                  start: DateTimeUtils.formatToDate(filter.dateDebut)!,
                  end: DateTimeUtils.formatToDate(filter.dateFin)!,
                }
              : null;
          break;
        case FilterType.SIMPLE_INPUT:
          inputValue = filter.simpleInputValue || undefined;
          break;
      }
    });

    return {
      rangeFilters: rangeCriteriaMap,
      selectFilters: fieldCriteriaMap,
      rangeDatesCriterias: rangeDatesCriteriaMap,
      inputValue,
    };
  }

  onDateRangeChange(event: RangeDate, filtre: FilterConfig): void {
    filtre.dateDebut = event.dateDebut;
    filtre.dateFin = event.dateFin;
  }

  resetFilters(filters: FilterConfig[]): void {
    filters.forEach(filter => {
      switch (filter.type) {
        case FilterType.RANGE:
          filter.min = undefined;
          filter.max = undefined;
          break;
        case FilterType.OUI_NON_RADIO_BUTTON:
          filter.selectedValue = undefined;
          break;
        case FilterType.RANGE_DATE:
          filter.dateDebut = undefined;
          filter.dateFin = undefined;
          break;
        case FilterType.SIMPLE_INPUT:
          filter.simpleInputValue = undefined;
          break;
        case FilterType.MULTI_SELECT_AUTO_COMPLETE:
          filter.dataList = [];
          filter.selectedList = [];
          break;
        case FilterType.MULTI_SELECT:
          filter.selectedList = [];
          break;
      }
    });
  }

  preventValues(event: KeyboardEvent) {
    const forbiddenKeys = ['-', ',', '.', 'e', 'E', '+'];
    if (forbiddenKeys.includes(event.key)) {
      event.preventDefault();
    }
  }
}
